import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GithubPicker } from 'react-color';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Divider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnackbar } from '../../components/SnackbarContext';
import {
  getDocs,
  doc,
  getDoc,
  setDoc,
  collection,
  deleteDoc,
  updateDoc,
  increment,
} from 'firebase/firestore';
import { getFirestoreLocation, auth, app } from '../../firebaseConfig';
import Tour from 'reactour';
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window';
import GridCell from '../../components/plots/RenderPlotForViewData';
import ProcessPlotData from '../../components/plots/ProcessPlotData';
import PestAndDiseaseLegend from '../../components/plots/RenderLegendForPlotView';
import PestDiseaseDashBoard from '../../components/plots/PestDiseaseDashboard';
import CellModal from '../../components/plots/CellModal';
import Loading from '../../components/Loading';
import RoleCheck from '../../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { getStorage, ref, deleteObject  } from 'firebase/storage';

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const YearWeekSelector = ({
  t,
  availableYears,
  selectedYear,
  handleYearChange,
  availableWeeks,
  selectedWeek,
  handleWeekChange,
  handlePreviousWeek,
  handleNextWeek,
  selectedWeekIndex,
}) => (
  <Box data-tour="yearWeek" sx={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
    <Box sx={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{t('Plots.Year')}</Typography>
        <Select
          sx={{ width: '6vw' }}
          value={selectedYear}
          onChange={handleYearChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Year' }}
        >
          <MenuItem value="" disabled>
            {t('Plots.Year')}
          </MenuItem>
          {availableYears.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{t('Plots.Week')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button onClick={handlePreviousWeek} disabled={selectedWeekIndex === 0}>
            <ArrowBackIcon />
          </Button>
          <Select
            sx={{ width: '5vw' }}
            value={selectedWeek}
            onChange={handleWeekChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select Week' }}
            disabled={!selectedYear}
          >
            <MenuItem value="" disabled>
              {t('Plots.Week')}
            </MenuItem>
            {availableWeeks.map((week) => (
              <MenuItem key={week} value={week}>
                {week}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleNextWeek} disabled={selectedWeekIndex === availableWeeks.length - 1}>
            <ArrowForwardIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  </Box>
);

const ColorPickerPopover = ({ anchorEl, tempColor, handleColorChange, saveColorChanges, setAnchorEl }) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={() => setAnchorEl(null)}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <GithubPicker
      colors={[
        '#B80000',
        '#f44336',
        '#ff5722',
        '#DB3E00',
        '#ff9800',
        '#ffc107',
        '#FCCB00',
        '#ffeb3b',
        '#cddc39',
        '#8bc34a',
        '#4caf50',
        '#008B02',
        '#006B76',
        '#009688',
        '#00bcd4',
        '#03a9f4',
        '#2196f3',
        '#3f51b5',
        '#1273DE',
        '#673ab7',
        '#9c27b0',
        '#e91e63',
        '#795548',
        '#607d8b',
      ]}
      color={tempColor}
      onChangeComplete={handleColorChange}
    />
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button variant="contained" color="primary" onClick={saveColorChanges} sx={{ mt: 1 }}>
        Save Colour
      </Button>
    </Box>
  </Popover>
);

const ZoomControls = ({ handleZoomChange }) => (
  <Box data-tour="zoom">
    <Button onClick={() => handleZoomChange(0.5)}>50%</Button>
    <Button onClick={() => handleZoomChange(1)}>100%</Button>
    <Button onClick={() => handleZoomChange(1.5)}>150%</Button>
  </Box>
);

const RowLabel = ({ index, style, data, zoom }) => (
  <div
    style={{
      ...style,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: zoom * 18,
      border: '1px solid #808080',
      boxSizing: 'border-box',
    }}
  >
    {data[index]}
  </div>
);

function ViewPlotData() {
  const { orgId, plotId, formId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [plotDetails, setPlotDetails] = useState(null);
  const [formDetails, setFormDetails] = useState(null);
  const [rawPestData, setRawPestData] = useState([]);
  const [rawDiseaseData, setRawDiseaseData] = useState([]);
  const [pestData, setPestData] = useState({});
  const [pestTypes, setPestTypes] = useState([]);
  const [diseaseData, setDiseaseData] = useState({});
  const [diseaseTypes, setDiseaseTypes] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');
  const [tempColor, setTempColor] = useState(null);
  const colorPickerRef = useRef();
  const [selectedType, setSelectedType] = useState({ type: null, isPest: true, anchor: null });
  const [pestTypeColors, setPestTypeColors] = useState({});
  const [diseaseTypeColors, setDiseaseTypeColors] = useState({});
  const [pestVisibility, setPestVisibility] = useState({});
  const [diseaseVisibility, setDiseaseVisibility] = useState({});
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [walkwayPosition, setWalkwayPosition] = useState(0);
  const [leftSideLabels, setLeftSideLabels] = useState({});
  const [rightSideLabels, setRightSideLabels] = useState({});
  const [columnLabels, setColumnLabels] = useState({});
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const scrollRef = useRef(null);
  const [currentView, setCurrentView] = useState('plot');
  const leftSideListRef = useRef();
  const rightSideListRef = useRef();
  const columnLabelsListRef = useRef();
  const [userRole, setUserRole] = useState('');
  const [zoom, setZoom] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('viewPlotDataTourSeen') === 'true');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCellData, setSelectedCellData] = useState(null);
  const REDIS_KEY_PREFIX = 'plot-data';
  const { width, height } = useWindowDimensions();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [cameraVisibility, setCameraVisibility] = useState(true);
  const [cameraData, setCameraData] = useState({});
  const [canUseCamera, setCanUseCamera] = useState(false);
  const [removedCells, setRemovedCells] = useState([]);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleTypeSelect = (event, type, isPest) => {
    setSelectedType({ type: type.id, isPest, anchor: event.currentTarget });
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setIsTourOpen(!tourSeen);
  }, [tourSeen]);

  useEffect(() => {
    const fetchCanUseCamera = async () => {
      if (firestore && orgId) {
        try {
          const docRef = doc(firestore, `organizations/${orgId}`);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data && data.canUseCamera !== undefined) {
              setCanUseCamera(data.canUseCamera);
            } else {
              console.error('canUseCamera field does not exist');
            }
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      }
    };

    fetchCanUseCamera();
  }, [firestore, orgId]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('viewPlotDataTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="yearWeek"]',
      content: 'Select the year and week you want to view submissions for.',
    },
    {
      selector: '[data-tour="colourPicker"]',
      content: 'Click the colours to change them to what you would like to see on your heatmap.',
    },
    {
      selector: '[data-tour="zoom"]',
      content: 'Change the zoom of your plot when viewing to focus on one point or have a general overview',
    },
    {
      selector: '[data-tour="colourIntensity"]',
      content: 'The darker the colour, the higher the intensity of pests/disease in that area',
    },
    {
      selector: '[data-tour="dashboard"]',
      content: 'Select this to visit the dashboard',
    },
    {
      selector: '',
      content: () => (
        <FormControlLabel
          control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
          label="Don't show this again"
        />
      ),
    },
  ];

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onScroll = ({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
    if (leftSideListRef.current) {
      leftSideListRef.current.scrollTo(scrollTop);
    }
    if (rightSideListRef.current) {
      rightSideListRef.current.scrollTo(scrollTop);
    }
    if (columnLabelsListRef.current) {
      columnLabelsListRef.current.scrollTo(scrollLeft);
    }
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  useEffect(() => {
    const index = availableWeeks.indexOf(selectedWeek);
    if (index !== -1) {
      setSelectedWeekIndex(index);
    }
  }, [availableWeeks, selectedWeek]);

  const handlePreviousWeek = () => {
    const previousIndex = Math.max(selectedWeekIndex - 1, 0);
    setSelectedWeek(availableWeeks[previousIndex]);
  };

  const handleNextWeek = () => {
    const nextIndex = Math.min(selectedWeekIndex + 1, availableWeeks.length - 1);
    setSelectedWeek(availableWeeks[nextIndex]);
  };

  const transformArrayToObject = (array) => array;

  useEffect(() => {
    const fetchAndInitializeColors = async (typeName, setColors, setTypes, questionId) => {
      const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
      const githubColors = [
        "#B80000", "#f44336", "#ff5722", "#DB3E00", "#ff9800", "#ffc107", "#FCCB00", "#ffeb3b", "#cddc39", "#8bc34a",
        "#4caf50", "#008B02", "#006B76", "#009688", "#00bcd4", "#03a9f4", "#2196f3", "#3f51b5", "#1273DE", "#673ab7",
        "#9c27b0", "#e91e63", "#795548", "#607d8b"
      ];

      try {
        const formSnapshot = await getDoc(formDocRef);
        if (!formSnapshot.exists()) {
          console.error(`Form document does not exist for path: ${formDocRef.path}`);
          return;
        }

        const formData = formSnapshot.data();

        const question = formData.questions.find(q => q.id === questionId);
        if (!question) {
          console.error(`Question with id ${questionId} not found in form data.`);
          return;
        }

        let shouldUpdateFirebase = false;
        const initializedColors = question.options.reduce((acc, option, index) => {
          if (!option.color) {
            option.color = githubColors[index % githubColors.length];
            shouldUpdateFirebase = true;
          }
          acc[option.id] = { text: option.text, color: option.color };
          return acc;
        }, {});

        setColors(initializedColors);

        if (shouldUpdateFirebase) {
          await setDoc(formDocRef, { questions: formData.questions }, { merge: true });
        }

        const typesWithIds = question.options.map(option => ({
          id: option.id,
          text: option.text
        }));
        setTypes(typesWithIds);
      } catch (error) {
        console.error(`Error fetching and initializing colors for ${typeName}:`, error);
        Sentry.captureException(error);
      }
    };

    fetchAndInitializeColors('Pest Type', setPestTypeColors, setPestTypes, 'question-pest-type');
    fetchAndInitializeColors('Disease Type', setDiseaseTypeColors, setDiseaseTypes, 'question-disease-type');
  }, [orgId, plotId, formId]);

  useEffect(() => {
    const initialPestVisibility = pestTypes.reduce((acc, type) => ({
      ...acc,
      [type.id]: { visible: true },
    }), {});
    setPestVisibility(initialPestVisibility);

    const initialDiseaseVisibility = diseaseTypes.reduce((acc, type) => ({
      ...acc,
      [type.id]: { visible: true },
    }), {});
    setDiseaseVisibility(initialDiseaseVisibility);
  }, [pestTypes, diseaseTypes]);

  const saveColorChanges = async () => {
    const { type, isPest } = selectedType;
    if (type && tempColor) {
      const updatedColors = isPest
        ? { ...pestTypeColors, [type]: { ...pestTypeColors[type], color: tempColor } }
        : { ...diseaseTypeColors, [type]: { ...diseaseTypeColors[type], color: tempColor } };

      const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);

      const updatedFormDetails = { ...formDetails };

      const questionId = isPest ? 'question-pest-type' : 'question-disease-type';
      const questionIndex = updatedFormDetails.questions.findIndex(q => q.id === questionId);
      if (questionIndex !== -1) {
        const optionIndex = updatedFormDetails.questions[questionIndex].options.findIndex(o => o.id === type);
        if (optionIndex !== -1) {
          updatedFormDetails.questions[questionIndex].options[optionIndex].color = tempColor;
          await setDoc(formDocRef, updatedFormDetails, { merge: true });

          if (isPest) {
            setPestTypeColors(updatedColors);
          } else {
            setDiseaseTypeColors(updatedColors);
          }

          setSelectedType({ type: null, isPest: true });
          setTempColor(null);
          setAnchorEl(null);
        }
      }
    }
  };

  const handleColorChange = (color) => {
    setTempColor(color.hex);
  };

  const handlePestCheckboxChange = (pestId) => {
    setPestVisibility((prevVisibility) => ({
      ...prevVisibility,
      [pestId]: { visible: !prevVisibility[pestId].visible },
    }));
  };

  const handleDiseaseCheckboxChange = (diseaseId) => {
    setDiseaseVisibility((prevVisibility) => ({
      ...prevVisibility,
      [diseaseId]: { visible: !prevVisibility[diseaseId].visible },
    }));
  };

  const selectAllPests = () => {
    setPestVisibility(pestTypes.reduce((acc, type) => ({ ...acc, [type.id]: { visible: true } }), {}));
  };

  const clearAllPests = () => {
    setPestVisibility(pestTypes.reduce((acc, type) => ({ ...acc, [type.id]: { visible: false } }), {}));
  };

  const selectAllDiseases = () => {
    setDiseaseVisibility(diseaseTypes.reduce((acc, type) => ({ ...acc, [type.id]: { visible: true } }), {}));
  };

  const clearAllDiseases = () => {
    setDiseaseVisibility(diseaseTypes.reduce((acc, type) => ({ ...acc, [type.id]: { visible: false } }), {}));
  };

  const adjustColorOpacity = (color, intensity) => {
    if (typeof color !== 'string' || color[0] !== '#' || color.length !== 7) {
      Sentry.captureException(new Error("Invalid color format"));
      return 'rgba(0, 0, 0, 1)';
    }

    if (typeof intensity !== 'number' || isNaN(intensity) || intensity < 0) {
      Sentry.captureException(new Error("Invalid intensity value"));
      return color;
    }

    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    const opacity = (intensity / 3).toFixed(2);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const cellWidth = 40;
  const cellHeight = 20;
  const columnCount = walkwayPosition > 0 ? columns + 1 : columns;
  const rowCount = rows;

  const cellData = {
    pestData,
    diseaseData,
    pestVisibility,
    diseaseVisibility,
    cameraVisibility,
    pestTypeColors,
    diseaseTypeColors,
    adjustColorOpacity,
    walkwayPosition,
    rawPestData,
    rawDiseaseData,
    cameraData,
    removedCells,
    onCellClick: (cellData) => setSelectedCellData(cellData),
  };

  useEffect(() => {
    const fetchPlotAndFormDetails = async () => {
      setLoading(true);
      const plotDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId);
      const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
      const orgDocRef = doc(firestore, 'organizations', orgId);

      try {
        const orgSnapshot = await getDoc(orgDocRef);
        const orgData = orgSnapshot.data();

        if (orgData.currentTotalData > orgData.maximumTotalData) {
          navigate('/home');
          showSnackbar(t('General.MaxTotalData'), 'error');
          return;
        }

        const plotSnapshot = await getDoc(plotDocRef);

        if (plotSnapshot.exists()) {
          const plotData = plotSnapshot.data();
          setPlotDetails(plotData);

          setPlotDetails(plotData);
          // Set removed cells if available in plot details
          if (plotData.removedCells) {
            setRemovedCells(plotData.removedCells);
          }

          setRows(plotData.rows);
          setColumns(plotData.columns);
          setWalkwayPosition(Number(plotData.walkwayPosition));
          setLeftSideLabels(transformArrayToObject(plotData.leftSideLabels || []));
          setRightSideLabels(transformArrayToObject(plotData.rightSideLabels || []));
          setColumnLabels(transformArrayToObject(plotData.columnLabels || []));
        }

        const formSnapshot = await getDoc(formDocRef);

        if (formSnapshot.exists()) {
          const formData = formSnapshot.data();
          setFormDetails(formData);

          const pestTypeQuestion = formData.questions.find((question) => question.id === 'question-pest-type');
          const derivedPestTypes = pestTypeQuestion ? pestTypeQuestion.options.map(option => ({ id: option.id, text: option.text })) : [];
          setPestTypes(derivedPestTypes);

          const diseaseTypeQuestion = formData.questions.find((question) => question.id === 'question-disease-type');
          const derivedDiseaseTypes = diseaseTypeQuestion ? diseaseTypeQuestion.options.map(option => ({ id: option.id, text: option.text })) : [];
          setDiseaseTypes(derivedDiseaseTypes);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    const fetchSubmissionYearsAndWeeks = async () => {
      setLoading(true);
      const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);

      try {
        const formDocSnapshot = await getDoc(formDocRef);
        if (formDocSnapshot.exists()) {
          const formData = formDocSnapshot.data();
          const submissionHistory = formData.submissionHistory || {};
          const availableYears = Object.keys(submissionHistory).sort((a, b) => Number(a) - Number(b));

          setAvailableYears(availableYears);
          if (availableYears.length > 0) {
            const latestYear = availableYears[availableYears.length - 1];
            setSelectedYear(latestYear);
            if (submissionHistory[latestYear]) {
              const availableWeeks = Object.keys(submissionHistory[latestYear]).sort((a, b) => Number(a) - Number(b));
              setAvailableWeeks(availableWeeks);
              if (availableWeeks.length > 0) {
                const latestWeek = availableWeeks[availableWeeks.length - 1];
                setSelectedWeek(latestWeek);
              }
            } else {
              console.log(`No weeks data available for the year ${latestYear}.`);
              setAvailableWeeks([]);
            }
          }
        } else {
          console.log('Document does not exist or has no submission history.');
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlotAndFormDetails();
    fetchSubmissionYearsAndWeeks();
  }, [orgId, plotId, formId]);

  const handleYearChange = async (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    setSelectedWeek('');

    const weeksCollectionRef = collection(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId, 'years', `${year}`, 'weeks');

    try {
      const weeksSnapshot = await getDocs(weeksCollectionRef);
      const availableWeeks = [];
      weeksSnapshot.forEach((doc) => {
        const weekNum = doc.id;
        availableWeeks.push(weekNum);
      });
      setAvailableWeeks(availableWeeks.sort((a, b) => a - b));
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const getOptionTextById = (questionId, id) => {
    if (!formDetails) return '';
    const question = formDetails.questions.find(q => q.id === questionId);
    const option = question.options.find(o => o.id === id);
    return option ? option.text : '';
  };

  const deleteImage = async (imagePath) => {
    try {
      const storage = getStorage(app, storageBucket);
      const imageRef = ref(storage, imagePath);

      await deleteObject(imageRef);
      showSnackbar(t('Plots.Snackbar.DeleteImageSuccess'),'success')
    } catch (error) {
      showSnackbar(t('Plots.Snackbar.DeleteImageError'),'error')
      Sentry.captureException(error);
    }
  };

  const deleteSubmission = async (docId, isPest) => {
    try {
      const docToDelete = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId, 'years', `${selectedYear}`, 'weeks', `${selectedWeek}`, 'submissions', docId);
      const docToDeleteSnap = await getDoc(docToDelete);
      const docToDeleteData = docToDeleteSnap.data();
      const cellPosition = docToDeleteData.cellPosition;
      const column = cellPosition.column;
      const row = cellPosition.row;
      const pestType = docToDeleteData.pestType;
      const pestLevel = docToDeleteData.pestLevel;
      const diseaseType = docToDeleteData.diseaseType;
      const diseaseLevel = docToDeleteData.diseaseLevel;
      const pestImage = docToDeleteData.pestImage;
      const diseaseImage = docToDeleteData.diseaseImage;

      const lastUpdatedRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId, 'years', `${selectedYear}`, 'weeks', `${selectedWeek}`);

      const formDoc = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
      const orgDocRef = doc(firestore, 'organizations', orgId);

      await updateDoc(lastUpdatedRef, {
        lastUpdated: new Date()
      });

      const imageType = isPest ? 'pests' : 'diseases';
      const imageBasePath = `${storageBucket}/${orgId}/${plotId}/${formId}/${selectedYear}/${selectedWeek}/${imageType}/${docId}`;
      
      if (pestImage || diseaseImage) {
        // Check the imageTracker array or assume default [true, false, false] if it doesn't exist
        const imageTracker = docToDeleteData.imageTracker || [true, false, false];
        
        // Delete all images as indicated by the imageTracker
        for (let i = 0; i < imageTracker.length; i++) {
          if (imageTracker[i]) {
            // Construct image path for each suffix, starting with no suffix, then _2, _3, etc.
            const suffix = i === 0 ? '' : `_${i + 1}`;
            const imagePath = `${imageBasePath}${suffix}.jpeg`;
    
            await deleteImage(imagePath);
          }
        }
      }

      if (isPest) {
        const rawPestDataVar = JSON.parse(JSON.stringify(rawPestData));
        const processedDataPestsVar = JSON.parse(JSON.stringify(pestData));

        const pestValue = pestLevel === 'Low' ? 1 : pestLevel === 'Medium' ? 2 : pestLevel === 'High' ? 3 : 0;
        const count = rawPestDataVar[row][column].filter(item => item.includes(`Type:${pestType}`)).length;
        const total = count * processedDataPestsVar[pestType][row][column];
        const newTotal = total - pestValue;
        const newCount = count - 1;
        const newAvg = newCount === 0 ? 0 : newTotal / newCount;
        const fieldPath = `submissionHistory.${selectedYear}.${selectedWeek}.pestCounters.${pestType}.${pestLevel}`;

        rawPestDataVar[row][column] = rawPestDataVar[row][column].filter(item => !item.includes(`DocId:${docId}`));
        processedDataPestsVar[pestType][row][column] = newAvg;

        await updateDoc(formDoc, {
          [fieldPath]: increment(-1)
        });

        if (diseaseType === '') {
          await deleteDoc(docToDelete);
        } else {
          await updateDoc(docToDelete, {
            pestType: ''
          });
        }
        
        const updatedPestData = selectedCellData.rawPestData.filter(item => !item.includes(`DocId:${docId}`));
        setSelectedCellData(prevData => ({
          ...prevData,
          rawPestData: updatedPestData,
        }));

        setRawPestData(rawPestDataVar);
        setPestData(processedDataPestsVar);

      } else {
        const rawDiseaseDataVar = JSON.parse(JSON.stringify(rawDiseaseData));
        const processedDataDiseaseVar = JSON.parse(JSON.stringify(diseaseData));

        const diseaseValue = diseaseLevel === 'Low' ? 1 : diseaseLevel === 'Medium' ? 2 : diseaseLevel === 'High' ? 3 : 0;
        const count = rawDiseaseDataVar[row][column].filter(item => item.includes(`Type:${diseaseType}`)).length;
        const total = count * processedDataDiseaseVar[diseaseType][row][column];
        const newTotal = total - diseaseValue;
        const newCount = count - 1;
        const newAvg = newCount === 0 ? 0 : newTotal / newCount;
        const fieldPath = `submissionHistory.${selectedYear}.${selectedWeek}.diseaseCounters.${diseaseType}.${diseaseLevel}`;

        rawDiseaseDataVar[row][column] = rawDiseaseDataVar[row][column].filter(item => !item.includes(`DocId:${docId}`));
        processedDataDiseaseVar[diseaseType][row][column] = newAvg;

        await updateDoc(formDoc, {
          [fieldPath]: increment(-1)
        });

        if (pestType === '') {
          await deleteDoc(docToDelete);
        } else {
          await updateDoc(docToDelete, {
            diseaseType: ''
          });
        }

        const updatedDiseaseData = selectedCellData.rawDiseaseData.filter(item => !item.includes(`DocId:${docId}`));
        setSelectedCellData(prevData => ({
          ...prevData,
          rawDiseaseData: updatedDiseaseData,
        }));
        
        setRawDiseaseData(rawDiseaseDataVar);
        setDiseaseData(processedDataDiseaseVar);
      }
      await updateDoc(orgDocRef, {
        currentTotalData: increment(-1)
      });

      // Decrement numberOfSubmissions
      await updateDoc(formDoc, {
        numberOfSubmissions: increment(-1)
      });

    } catch (error) {
      showSnackbar('Error deleting submission, please try again.', 'error');
      Sentry.captureException(error);
    }
  };

  const handlePestDiseaseDataFetched = (data) => {
    setPestData(data.pests || {});
    setDiseaseData(data.diseases || {});
    setCameraData(data.camera);
  };

  const handleRawPestDiseaseDataFetched = (data) => {
    setRawPestData(data.pests || {});
    setRawDiseaseData(data.diseases || {});
  };

  const adjustedColumnCount = walkwayPosition > 0 ? columns + 1 : columns;

  const renderView = () => {
    if (currentView === 'plot') {
      return (
        <Box sx={{ display: 'flex' }}>
          <Box
            variant="permanent"
            sx={{
              width: '20vw',
              flexShrink: 1,
              outline: '2px solid #3C3C3C',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '4px',
              marginBottom: '4px',
              height: '84vh',
              padding: 2,
              minWidth: '200px'
            }}
          >
            <Box sx={{ my: 1, width: '100%' }}>
              {plotDetails && formDetails && (
                <Typography variant="h6" gutterBottom>
                  {plotDetails.plotName} - {formDetails.formName}
                </Typography>
              )}

              <YearWeekSelector
                t={t}
                availableYears={availableYears}
                selectedYear={selectedYear}
                handleYearChange={handleYearChange}
                availableWeeks={availableWeeks}
                selectedWeek={selectedWeek}
                handleWeekChange={handleWeekChange}
                handlePreviousWeek={handlePreviousWeek}
                handleNextWeek={handleNextWeek}
                selectedWeekIndex={selectedWeekIndex}
              />

              {selectedType.type && (
                <Box ref={colorPickerRef} sx={{ zIndex: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                  <ColorPickerPopover
                    anchorEl={anchorEl}
                    tempColor={tempColor || (selectedType.isPest ? pestTypeColors[selectedType.type] : diseaseTypeColors[selectedType.type])}
                    handleColorChange={handleColorChange}
                    saveColorChanges={saveColorChanges}
                    setAnchorEl={setAnchorEl}
                  />
                </Box>
              )}
              <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100% - 150px)' }}>
                <PestAndDiseaseLegend
                  pestTypeColors={pestTypeColors}
                  diseaseTypeColors={diseaseTypeColors}
                  onTypeChange={(type, isPest) => setSelectedType({ type: type.id, isPest })}
                  pestVisibility={pestVisibility}
                  diseaseVisibility={diseaseVisibility}
                  cameraVisibility={cameraVisibility}
                  handleTypeSelect={handleTypeSelect}
                  onSelectAllPests={selectAllPests}
                  onClearAllPests={clearAllPests}
                  onSelectAllDiseases={selectAllDiseases}
                  onClearAllDiseases={clearAllDiseases}
                  onPestCheckboxChange={handlePestCheckboxChange}
                  onDiseaseCheckboxChange={handleDiseaseCheckboxChange}
                  setCameraVisibility={setCameraVisibility}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <ZoomControls handleZoomChange={handleZoomChange} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
              }}
            >
              <List
                height={zoom * 38}
                width={width * 0.68}
                itemCount={adjustedColumnCount}
                itemSize={zoom * cellWidth}
                itemData={{
                  labels: columnLabels,
                  zoom,
                }}
                style={{ marginLeft: '70px', overflow: 'hidden', marginBottom: '-10px', paddingRight: '25px'}}
                layout="horizontal"
                ref={columnLabelsListRef}
              >
                {({ index, style }) => (
                  <Typography
                    style={{
                      ...style,
                      fontSize: zoom * 12,
                      border: '1px solid #808080',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxSizing: 'border-box',
                    }}
                  >
                    {columnLabels[index]}
                  </Typography>
                )}
              </List>
              <Paper
                sx={{
                  display: 'flex',
                  marginLeft: '20px',
                  marginTop: '12px',
                }}
                elevation={0}
                ref={scrollRef}
              >
                <List
                  height={height * 0.75}
                  itemCount={rows}
                  itemSize={zoom * cellHeight}
                  width={50}
                  ref={leftSideListRef}
                  itemData={Object.values(leftSideLabels)}
                  style={{ marginTop: '-2px', overflow: 'hidden', paddingBottom: '20px' }}
                >
                  {({ index, style }) => (
                    <RowLabel index={index} style={style} data={Object.values(leftSideLabels)} zoom={zoom} />
                  )}
                </List>
                <Grid
                  columnCount={columnCount}
                  columnWidth={zoom * cellWidth}
                  height={height * 0.75}
                  rowCount={rowCount}
                  rowHeight={zoom * cellHeight}
                  width={width * 0.68}
                  itemData={cellData}
                  onScroll={onScroll}
                  style={{ outline: '2px solid black' }}
                >
                  {GridCell}
                </Grid>
                <List
                  height={height * 0.75}
                  itemCount={rows}
                  itemSize={zoom * cellHeight}
                  width={50}
                  ref={rightSideListRef}
                  itemData={Object.values(rightSideLabels)}
                  style={{ marginTop: '-2px', overflow: 'hidden', paddingBottom: '20px' }}
                >
                  {({ index, style }) => (
                    <RowLabel index={index} style={style} data={Object.values(rightSideLabels)} zoom={zoom} />
                  )}
                </List>
              </Paper>
              {rows > 0 && columns > 0 && selectedYear && selectedWeek && (
                <>
                  {isProcessing && (
                    <Loading message="Please wait, data is loading..."  size={50} />
                  )}
                  <ProcessPlotData
                    orgId={orgId}
                    location={location}
                    plotId={plotId}
                    formId={formId}
                    selectedYear={Number(selectedYear)}
                    selectedWeek={Number(selectedWeek)}
                    rows={rows}
                    columns={columns}
                    onPestDiseaseDataFetched={handlePestDiseaseDataFetched}
                    onRawPestDiseaseDataFetched={handleRawPestDiseaseDataFetched}
                    setIsProcessing={setIsProcessing}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      );
    } else if (currentView === 'dashboard') {
      return <PestDiseaseDashBoard orgId={orgId} plotId={plotId} formId={formId} location={location} />;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100%', overflow: 'hidden' }}>
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner', 'Contributor', 'Viewer']}
        redirectPage="/home"
        firestore={firestore}
        setUserRole={setUserRole}
      />
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <Button variant={currentView === 'plot' ? 'outlined' : 'contained'} onClick={() => handleViewChange('plot')}>
          {t('Plots.ViewPlot')}
        </Button>
        <Button
          data-tour="dashboard"
          variant={currentView === 'dashboard' ? 'outlined' : 'contained'}
          onClick={() => handleViewChange('dashboard')}
        >
          {t('Plots.ViewDashboard')}
        </Button>
      </Box>
      {/* Divider within the button container */}
      <Divider
        sx={{
          borderColor: 'primary.main',
          borderBottomWidth: 2,
          width: '95vw',
          mt: 1,
        }}
      />
    </Box>
      {renderView()}
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
      <CellModal
        selectedCellData={selectedCellData}
        setSelectedCellData={setSelectedCellData}
        getOptionTextById={getOptionTextById}
        deleteSubmission={deleteSubmission}
        t={t}
        showSnackbar={showSnackbar}
        canUseCamera={canUseCamera}
        storageBucket={storageBucket}
        app={app}
        orgId={orgId}
        plotId={plotId}
        formId={formId}
        selectedYear={selectedYear}
        selectedWeek={selectedWeek}
      />
    </Box>
  );
}

export default ViewPlotData;
