import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { BarChart, PieChart, LineChart } from '@mui/x-charts';
import {
  TextField,
  MenuItem,
  Grid,
  Checkbox,
  Paper, Button, Typography, List, ListItem, ListItemIcon, ListItemText, Box, Container,
} from '@mui/material';
import { getFirestoreLocation } from '../../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../SnackbarContext';
import * as Sentry from '@sentry/react';

function PestDiseaseDashBoard({ orgId, plotId, formId, location }) {
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [pestChartData, setPestChartData] = useState({ series: [], xAxis: [], pieData: [] });
  const [diseaseChartData, setDiseaseChartData] = useState({ series: [], xAxis: [], pieData: [] });
  const [availableYears, setAvailableYears] = useState([]);
  const [startAvailableWeeks, setStartAvailableWeeks] = useState([]);
  const [endAvailableWeeks, setEndAvailableWeeks] = useState([]);
  const [selectedStartYear, setSelectedStartYear] = useState('');
  const [selectedStartWeek, setSelectedStartWeek] = useState('');
  const [selectedEndYear, setSelectedEndYear] = useState('');
  const [selectedEndWeek, setSelectedEndWeek] = useState('');
  const [currentDashboard, setCurrentDashboard] = useState('Pest');
  const isPestDashboard = currentDashboard === 'Pest';
  const chartData = isPestDashboard ? pestChartData : diseaseChartData;
  const noDataMessage = isPestDashboard ? "No pest data available for the selected filters." : "No disease data available for the selected filters.";
  const [selectedPestOrDisease, setSelectedPestOrDisease] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [weeksSubmissions, setWeeksSubmissions] = useState([]);
  const [pestOptions, setPestOptions] = useState([]);
  const [diseaseOptions, setDiseaseOptions] = useState([]);
  const [pestColors, setPestColors] = useState({ default: '#CCCCCC' });
  const [diseaseColors, setDiseaseColors] = useState({ default: '#CCCCCC' });
  const [formData, setFormData] = useState(null);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  
  // Fetch Form Data
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
        const formDocSnapshot = await getDoc(formDocRef);

        if (formDocSnapshot.exists()) {
          const data = formDocSnapshot.data();
          setFormData(data);
          setPestColors(data.pestColours || { default: '#CCCCCC' });
          setDiseaseColors(data.diseaseColours || { default: '#CCCCCC' });
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
        }
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      }
    };
    fetchFormData();
  }, [firestore, orgId, plotId, formId]);

  // Update the available years and set the initial start and end weeks and years
  useEffect(() => {
    try {
      if (formData && formData.submissionHistory) {
        const submissionHistory = formData.submissionHistory;
        const years = Object.keys(submissionHistory).sort((a, b) => b - a);
        setAvailableYears(years);
  
        if (years.length > 0) {
          // Set initial start and end years based on available years
          const initialYear = years[0];
          const weeks = Object.keys(submissionHistory[initialYear] || {})
            .map(Number) // Ensure weeks are treated as numbers
            .sort((a, b) => a - b); // Sort numerically
  
          // Initialize start and end week dropdowns with the available weeks
          setStartAvailableWeeks(weeks);
          setEndAvailableWeeks(weeks);
  
          if (weeks.length > 0) {
            setSelectedStartYear(initialYear);
            setSelectedEndYear(initialYear);
            setSelectedStartWeek(weeks[0]);
            setSelectedEndWeek(weeks[weeks.length - 1]);
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formData]);

  // Update available end weeks based on selected start week and year
  useEffect(() => {
    if (selectedStartYear && selectedEndYear) {
      if (selectedEndYear === selectedStartYear) {
        // When start and end year are the same, only show weeks >= start week
        setEndAvailableWeeks(
          startAvailableWeeks.filter(week => week >= selectedStartWeek)
        );
      } else {
        // When years are different, show all weeks for the end year
        const endYearWeeks = Object.keys(formData.submissionHistory[selectedEndYear] || {})
          .map(Number)
          .sort((a, b) => a - b);
  
        setEndAvailableWeeks(endYearWeeks);
      }
    }
  }, [selectedStartWeek, selectedStartYear, selectedEndYear, formData, startAvailableWeeks]);

  // Populate the pest disease options
  useEffect(() => {
    if (formData) {
      fetchPestDiseaseOptions(formData, currentDashboard).then((options) => {
        if (options.length > 0) {
          // Set all options as selected by default
          setSelectedPestOrDisease(options.map(option => option.id));
        } else {
          setSelectedPestOrDisease([]); // Fallback if no options are found
        }
      });
    }
  }, [formData, currentDashboard]);

  // Fetch bar pie chart submissions
  useEffect(() => {
    if (formData && selectedPestOrDisease.length > 0) {
      // Ensure pestOptions or diseaseOptions are populated based on currentDashboard
      if ((isPestDashboard && pestOptions.length > 0) || (!isPestDashboard && diseaseOptions.length > 0)) {
        fetchBarPieChartSubmissions(selectedStartYear, selectedStartWeek, selectedEndYear, selectedEndWeek, formData);
      }
    }
  }, [selectedStartYear, selectedStartWeek, selectedEndYear, selectedEndWeek, formData, currentDashboard, selectedPestOrDisease, pestOptions, diseaseOptions]); 

  // Fetch line chart submissions
  useEffect(() => {
    try {
      if (formData && selectedPestOrDisease && selectedStartYear && selectedStartWeek && selectedEndYear && selectedEndWeek) {
        fetchLineChartSubmissions(formData);
      } else {
        setLineChartData([]);
        setWeeksSubmissions([]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formData, selectedPestOrDisease, currentDashboard, selectedStartYear, selectedStartWeek, selectedEndYear, selectedEndWeek]);  

  const handleStartYearChange = async (event) => {
    try {
      const newStartYear = event.target.value;
      setSelectedStartYear(newStartYear);
      
      // Update start weeks based only on the new start year
      const weeks = Object.keys(formData.submissionHistory[newStartYear] || {})
        .map(Number)
        .sort((a, b) => a - b);
      setStartAvailableWeeks(weeks);

      if (!selectedStartWeek || !weeks.includes(parseInt(selectedStartWeek))) {
        setSelectedStartWeek(weeks[0]);
      }
  
      // Adjust the end year and end week if needed
      if (newStartYear > selectedEndYear || !selectedEndYear) {
        setSelectedEndYear(newStartYear);
        setSelectedEndWeek(weeks[weeks.length - 1]);
        setEndAvailableWeeks(weeks); // Sync end weeks with new start year if adjusting end year
      }

    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleStartWeekChange = (event) => {
    try {
      const newStartWeek = event.target.value;
      setSelectedStartWeek(newStartWeek);
  
      // Adjust the end week if the start week is after the end week
      if (selectedStartYear === selectedEndYear && newStartWeek > selectedEndWeek) {
        setSelectedEndWeek(newStartWeek);
      }

    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleEndYearChange = async (event) => {
    try {
      const newEndYear = event.target.value;
      setSelectedEndYear(newEndYear);
      
      // Update end weeks based only on the new end year
      const weeks = Object.keys(formData.submissionHistory[newEndYear] || {})
        .map(Number)
        .sort((a, b) => a - b);
      setEndAvailableWeeks(weeks);
  
      if (!selectedEndWeek || !weeks.includes(parseInt(selectedEndWeek))) {
        setSelectedEndWeek(weeks[weeks.length - 1]);
      }
  
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  
  const handleEndWeekChange = (event) => {
    try {
      const newEndWeek = event.target.value;
      setSelectedEndWeek(newEndWeek);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const toggleDashboard = () => {
    try {
      if (!formData) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return;
      }
      setCurrentDashboard(current => {
        const newDashboard = current === 'Pest' ? 'Disease' : 'Pest';
        const options = newDashboard === 'Pest' ? pestOptions : diseaseOptions;
        if (newDashboard === 'Pest') {
          setPestColors(formData.pestColours || { default: '#CCCCCC' });
        } else {
          setDiseaseColors(formData.diseaseColours || { default: '#CCCCCC' });
        }
        setSelectedPestOrDisease(options.length > 0 ? [options[0].id] : []);

        // Clears line chart so stale data does not show
        if (
          (newDashboard === 'Pest' && (!pestChartData.series.length || !weeksSubmissions.length)) ||
          (newDashboard === 'Disease' && (!diseaseChartData.series.length || !weeksSubmissions.length))
        ) {
          setLineChartData([]);
        }

        return newDashboard;
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  async function fetchPestDiseaseOptions(formData, type) {
    try {
      const submissionHistory = formData.submissionHistory || {};
      let counts = {};
  
      // Iterate through all years and weeks in the submission history
      Object.keys(submissionHistory)
        .map(Number)
        .sort((a, b) => a - b)
        .forEach(year => {
          const weeks = Object.keys(submissionHistory[year] || {}).map(Number).sort((a, b) => a - b);
  
          // Accumulate options based on pest or disease counters for all weeks
          weeks.forEach(week => {
            const weekData = submissionHistory[year][week];
            const data = type === 'Pest' ? weekData.pestCounters : weekData.diseaseCounters;
  
            Object.keys(data || {}).forEach(optionId => {
              counts[optionId] = true;
            });
          });
        });
  
      // Map options to include labels and colors
      const getLabelAndColorById = (questionId, id) => {
        const question = formData.questions.find(q => q.id === questionId);
        if (!question) return { text: id, color: '#CCCCCC' };
        const option = question.options.find(o => o.id === id);
        return option ? { text: option.text, color: option.color } : { text: id, color: '#CCCCCC' };
      };

      const options = Object.keys(counts).map(id => {
        const { text, color } = getLabelAndColorById(type === 'Pest' ? 'question-pest-type' : 'question-disease-type', id);
        return { id, name: text, color };
      }).sort((a, b) => a.name.localeCompare(b.name));

      if (type === 'Pest') {
        setPestOptions(options);
        return options;
      } else {
        setDiseaseOptions(options);
        return options;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function fetchBarPieChartSubmissions(startYear, startWeek, endYear, endWeek, formData) {
    try {
      if (!formData || !formData.submissionHistory) {
        showSnackbar(t('Plots.Snackbar.NoData'), 'warning');
        return;
      }
  
      const submissionHistory = formData.submissionHistory;
      let pestCounts = {};
      let diseaseCounts = {};
  
      // Filter based on selectedPestOrDisease
      Object.keys(submissionHistory).map(Number).sort((a, b) => a - b).forEach(year => {
        const yr = parseInt(year); // Ensure year is treated as a number
        if (yr < parseInt(startYear) || yr > parseInt(endYear)) return;
  
        Object.keys(submissionHistory[yr] || {})
          .map(Number) // Treat weeks as numbers
          .sort((a, b) => a - b) // Sort numerically
          .forEach(week => {
            const wk = parseInt(week); // Ensure week is treated as a number
            const withinRange = 
              (yr > parseInt(startYear) && yr < parseInt(endYear)) ||
              (yr === parseInt(startYear) && wk >= parseInt(startWeek) && 
              (yr !== parseInt(endYear) || wk <= parseInt(endWeek))) ||
              (yr === parseInt(endYear) && wk <= parseInt(endWeek) && 
              (yr !== parseInt(startYear) || wk >= parseInt(startWeek)));         
            if (!withinRange) return;
  
            // Aggregate counts based on the range filter
            const weekData = submissionHistory[yr][wk];
            if (weekData.pestCounters) {
              Object.entries(weekData.pestCounters).forEach(([type, counts]) => {
                if (selectedPestOrDisease.includes(type)) { // Only include selected pests
                  if (!pestCounts[type]) pestCounts[type] = { Low: 0, Medium: 0, High: 0 };
                  Object.entries(counts).forEach(([level, count]) => {
                    pestCounts[type][level] += count;
                  });
                }
              });
            }
            if (weekData.diseaseCounters) {
              Object.entries(weekData.diseaseCounters).forEach(([type, counts]) => {
                if (selectedPestOrDisease.includes(type)) { // Only include selected diseases
                  if (!diseaseCounts[type]) diseaseCounts[type] = { Low: 0, Medium: 0, High: 0 };
                  Object.entries(counts).forEach(([level, count]) => {
                    diseaseCounts[type][level] += count;
                  });
                }
              });
            }
          });
      });
  
      processChartData(pestCounts, diseaseCounts, formData); // This processes and updates the Bar and Pie chart data
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function fetchLineChartSubmissions(formData) {
    try {
      if (!selectedPestOrDisease.length || !selectedStartYear || !selectedEndYear) return;
     
      const submissionHistory = formData.submissionHistory;
      const aggregatedData = { Low: [], Medium: [], High: [] };
      const weeksArray = [];
  
      Object.keys(submissionHistory).map(Number).sort((a, b) => a - b).forEach(year => {
        const yr = parseInt(year); // Ensure year is treated as a number
        //if (yr < parseInt(selectedStartYear) || yr > parseInt(selectedEndYear)) return;

        Object.keys(submissionHistory[yr] || {})
          .map(Number) // Treat weeks as numbers
          .sort((a, b) => a - b) // Sort numerically
          .forEach(week => {
            const wk = parseInt(week); // Ensure week is treated as a number
            const withinRange = 
              (yr > parseInt(selectedStartYear) && yr < parseInt(selectedEndYear)) ||
              (yr === parseInt(selectedStartYear) && wk >= parseInt(selectedStartWeek) && 
              (yr !== parseInt(selectedEndYear) || wk <= parseInt(selectedEndWeek))) ||
              (yr === parseInt(selectedEndYear) && wk <= parseInt(selectedEndWeek) && 
              (yr !== parseInt(selectedStartYear) || wk >= parseInt(selectedStartWeek)));         
            if (!withinRange) return;
            
            weeksArray.push(`${yr} - ${wk}`);
            const weekData = submissionHistory[yr][wk];
            let lowSum = 0, mediumSum = 0, highSum = 0;
  
            selectedPestOrDisease.forEach(item => {
              const counts = (isPestDashboard ? weekData.pestCounters : weekData.diseaseCounters) || {};
              lowSum += counts[item]?.Low || 0;
              mediumSum += counts[item]?.Medium || 0;
              highSum += counts[item]?.High || 0;
            });
  
            aggregatedData.Low.push(lowSum);
            aggregatedData.Medium.push(mediumSum);
            aggregatedData.High.push(highSum);
          });
      });
  
      setLineChartData(['Low', 'Medium', 'High'].map(level => ({
        id: level,
        label: level,
        data: aggregatedData[level],
        stack: 'total',
        area: true,
      })));
      setWeeksSubmissions(weeksArray);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  function processChartData(pestCounts, diseaseCounts, formData) {
    try {
      const counts = isPestDashboard ? pestCounts : diseaseCounts;
      const itemTypes = Object.keys(counts).filter(type => selectedPestOrDisease.includes(type));
  
      if (itemTypes.length === 0) {
        const noData = { series: [], xAxis: [], pieData: [] };
        if (isPestDashboard) {
          setPestChartData(noData);
        } else {
          setDiseaseChartData(noData);
        }
        return;
      }
  
      let series = ['Low', 'Medium', 'High'].map(level => ({
        label: level,
        data: itemTypes.map(itemType => counts[itemType][level] || 0),
      }));
  
      const getLabelAndColorById = (id) => {
        const options = isPestDashboard ? pestOptions : diseaseOptions;
        const item = options.find(option => option.id === id);
        return item ? { text: item.name, color: item.color || '#CCCCCC' } : { text: id, color: '#CCCCCC' };
      };
  
      const innerPieData = itemTypes.map(itemType => {
        const { text, color } = getLabelAndColorById(itemType);
        return {
          label: text,
          value: ['Low', 'Medium', 'High'].reduce((total, level) => total + (counts[itemType][level] || 0), 0),
          color: color || '#CCCCCC',
        };
      });
  
      const outerPieData = itemTypes.flatMap(itemType =>
        ['Low', 'Medium', 'High'].map(level => {
          const { text, color } = getLabelAndColorById(itemType);
          const intensity = level === 'High' ? 1 : level === 'Medium' ? 0.7 : 0.4;
          const adjustedColor = adjustColorOpacity(color, intensity);
          return {
            label: `${text} ${level}`,
            value: counts[itemType][level] || 0,
            color: adjustedColor,
          };
        })
      );
  
      const xAxisData = [{ data: itemTypes.map(itemType => getLabelAndColorById(itemType).text), scaleType: 'band' }];
      const chartData = {
        series,
        xAxis: xAxisData,
        pieData: [innerPieData, outerPieData],
      };
  
      if (isPestDashboard) {
        setPestChartData(chartData);
      } else {
        setDiseaseChartData(chartData);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  const adjustColorOpacity = (color, intensity) => {
    // Convert hex color to RGB
    try {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const opacity = (intensity / 1.2);

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const CustomPieChartLegend = ({ data }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <Typography variant="subtitle1">{t('Plots.Legend')}</Typography>
      <List dense>
        {data.filter(entry => entry.value > 0).map((entry, index) => (  // Filter out entries with zero values
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
              <Box sx={{ width: 14, height: 14, backgroundColor: entry.color, borderRadius: '50%' }} />
            </ListItemIcon>
            <ListItemText primary={entry.label} sx={{ margin: 0 }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const isDataAvailable = availableYears.length > 0 && startAvailableWeeks.length > 0 && formData;

  return (
    <Container maxWidth={false} disableGutters={true} sx={{padding:'0', margin: '0', overflow: 'auto' }}>
      <Grid container style={{ marginTop: 14, justifyContent: 'space-around', alignItems: 'center', marginBottom: 15 }}>
        {/* Switch Button on the Left */}
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Button onClick={toggleDashboard} variant="contained">
            {t('Plots.SwitchTo')}
            {isPestDashboard ? ' Disease ' : ' Pest '}
            {t('Plots.Dashboard')}
          </Button>
        </Grid>

        {/* Start Year and Start Week Selectors */}
        <Grid item xs={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                select
                label='Start Year'
                value={selectedStartYear}
                onChange={handleStartYearChange}
                variant="outlined"
                fullWidth
              >
                {availableYears.map(year => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label='Start Week'
                value={selectedStartWeek}
                onChange={handleStartWeekChange}
                variant="outlined"
                fullWidth
              >
                {startAvailableWeeks.map(week => (
                  <MenuItem key={week} value={week}>{t('Plots.Week')} {week}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* End Year and End Week Selectors */}
        <Grid item xs={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                select
                label='End Year'
                value={selectedEndYear}
                onChange={handleEndYearChange}
                variant="outlined"
                fullWidth
              >
                {availableYears.map(year => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label='End Week'
                value={selectedEndWeek}
                onChange={handleEndWeekChange}
                variant="outlined"
                fullWidth
              >
                {endAvailableWeeks.map(week => (
                  <MenuItem key={week} value={week}>{t('Plots.Week')} {week}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        {/* Pest or Disease Selector */}
        <Grid item xs={2}>
          <TextField
            select
            label={t('Plots.SelectPestDisease')}
            value={selectedPestOrDisease}
            onChange={(e) => setSelectedPestOrDisease(e.target.value)}
            variant="outlined"
            fullWidth
            disabled={!isDataAvailable}
            SelectProps={{
              multiple: true,
              renderValue: (selected) =>
                (isPestDashboard ? pestOptions : diseaseOptions)
                  .filter(option => selected.includes(option.id))
                  .map(option => option.name)
                  .join(', ')
            }}
          >
            {(isPestDashboard ? pestOptions : diseaseOptions).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox checked={selectedPestOrDisease.includes(option.id)} />
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid style={{backgroundColor:'grey'}}>
        {/* Charts Section */}
        <Grid container justifyContent="space-around" alignItems="center" spacing={1} style={{ marginTop: 0, paddingLeft:5, paddingRight: 5 }}>
          {/* Bar Chart Section */}
          <Grid item xs={12} md={8}>
            <Paper style={{ padding: 1, height: '100%', width: '100%' }}>
              {chartData.series.length > 0 ? (
                <BarChart
                  height={300}
                  series={chartData.series}
                  xAxis={chartData.xAxis}
                  yAxis={[{ label: 'Number of Observations' }]}
                />
              ) : (
                <p>{noDataMessage}</p>
              )}
            </Paper>
          </Grid>

          {/* Pie Chart Section */}
          <Grid item xs={12} md={4}>
            <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            {chartData.pieData.length > 0 &&
            (isPestDashboard ? pestOptions : diseaseOptions).every(option => option.color) ? (
                <>
                  <PieChart
                    series={[
                      {
                        innerRadius: 0,
                        outerRadius: 80,
                        data: chartData.pieData[0].map(entry => ({
                          ...entry,
                          color: entry.color || '#CCCCCC',
                        })),
                      },
                      {
                        innerRadius: 100,
                        outerRadius: 120,
                        data: chartData.pieData[1],
                      },
                    ]}
                    width={400}
                    height={300}
                    slotProps={{
                      legend: { hidden: true },
                    }}
                  />
                  <CustomPieChartLegend data={chartData.pieData[0]} />
                </>
              ) : (
                <p>{noDataMessage}</p>
              )}
            </Paper>
          </Grid>
        </Grid>
        {/* Line Chart Pest/Disease Dropdown Section */}
        <Grid container justifyContent="center" style={{ marginTop: 7, paddingLeft:5, paddingRight: 5, marginBottom: 0 }}>
          {/* Line Chart Section */}
          <Grid item xs={12} style={{ width: '100vw' }}>
            <Paper style={{ padding: 2 }}>
              {lineChartData.length > 0 && weeksSubmissions.length > 0 ? (
                <LineChart
                  series={lineChartData}
                  xAxis={[
                    {
                      id: 'weeks',
                      data: weeksSubmissions.map(week => `${week}`),
                      scaleType: 'band',
                      label: 'Year - Week Number'
                    },
                  ]}
                  yAxis={[
                    {
                      id: 'y',
                      min: 0,
                      label: 'Number of Observations'
                    },
                  ]}
                  height={300}
                />
              ) : (
                <p>{noDataMessage}</p>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PestDiseaseDashBoard;
