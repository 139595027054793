import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Divider,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
  increment,
  updateDoc,
  query,
  where
} from 'firebase/firestore';
import Tour from 'reactour';
import { useSnackbar } from '../../components/SnackbarContext';
import {getFirestoreLocation, auth, app} from '../../firebaseConfig';
import RoleCheck from '../../utils/roleCheck';
import DeleteDialogBox from '../../components/DeleteDialogBox';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import {deleteObject, getStorage, ref} from "firebase/storage";
import axios from "axios";


function ExploreMyPlot() {
  const { orgId, plotId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [forms, setForms] = useState([]);
  const [plotName, setPlotName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [orgDataLimits, setOrgDataLimits] = useState({
    currentTotalData: 0,
    maximumTotalData: 0,
  });
  const navigate = useNavigate();
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourSeen, setTourSeen] = useState(
      localStorage.getItem('exploreMyPlotsTourSeen') === 'true'
  );
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: null, name: null });
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsTourOpen(!tourSeen);
  }, [tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('exploreMyPlotsTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="createScoutingEntries"]',
      content: t('Plots.ReactTour.Desc.6'),
    },
    {
      selector: '',
      content: () => (
          <FormControlLabel
              control={
                <Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />
              }
              label={t('General.DoNotShow')}
          />
      ),
    },
  ];

  useEffect(() => {
    try {
      const fetchOrgDataLimits = async () => {
        if (firestore && orgId) {
          const orgDocRef = doc(firestore, 'organizations', orgId);
          const orgDoc = await getDoc(orgDocRef);
          if (orgDoc.exists()) {
            const { currentTotalData, maximumTotalData } = orgDoc.data();
            setOrgDataLimits({ currentTotalData, maximumTotalData });
          }
        }
      };

      fetchOrgDataLimits();
    } catch (error) {
      Sentry.captureException(error);
    }

  }, [firestore, orgId]);

  useEffect(() => {
    if (firestore) {
      const fetchPlotDetails = async () => {
        try {
          const plotDoc = await getDoc(
            doc(firestore, 'organizations', orgId, 'plots', plotId)
          );
          if (plotDoc.exists()) {
            setPlotName(plotDoc.data().plotName);
          }
        } catch (error) {
          showSnackbar('Error fetching plot details', 'error');
          Sentry.captureException(error);
        }
      };

      const fetchForms = async () => {
        const formsCollection = collection(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots'
        );

        // Create a query to fetch only the forms where the "deleted" field is false
        const formsQuery = query(formsCollection, where('deleted', '==', false));

        try {
          const snapshot = await getDocs(formsQuery);
          const fetchedForms = snapshot.docs.map((doc) => ({
            id: doc.id, // This is the Firestore document name
            formName: doc.data().formName, // This assumes each document has a 'formName' field
          }));
          setForms(fetchedForms);
        } catch (error) {
          showSnackbar('Error fetching forms', 'error');
          Sentry.captureException(error);
        }
      };

      fetchPlotDetails();
      fetchForms();
    }
  }, [firestore, orgId, plotId, showSnackbar]);

  const handleCreateFormClick = () => {
    navigate(`/create-forms-for-plots/${orgId}/${location}/${plotId}`);
  };

  const handleEditFormClick = (formId) => {
    navigate(`/edit-form-for-plot/${orgId}/${location}/${plotId}/${formId}`);
  };

  const handleEnterDataClick = (formId) => {
    navigate(`/plot-data-entry/${orgId}/${location}/${plotId}/${formId}`);
  };

  const handleViewDataClick = (formId) => {
    navigate(`/view-plot-data/${orgId}/${location}/${plotId}/${formId}`);
  };

  const handleDeleteFormClick = (formId, formName) => {
    setSelectedItem({ id: formId, name: formName });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem({ id: null, name: null });
  };

  const handleDeleteForm = async () => {
    if (selectedItem.id) {
      try {
        const formId = selectedItem.id;
        let totalSubmissionsToDelete = 0;

        const plotFormRef = doc(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots',
            formId
        );

        const formSnapshot = await getDoc(plotFormRef);
        const formData = formSnapshot.data();

        if (formData.numberOfSubmissions) {
          totalSubmissionsToDelete += formData.numberOfSubmissions;
        }

        const orgDocRef = doc(firestore, 'organizations', orgId);
        await updateDoc(orgDocRef, {
          currentTotalData: increment(-totalSubmissionsToDelete),
        });

        await updateDoc(plotFormRef, {deleted: true});

        const imgData = {
          mode: import.meta.env.VITE_WEB_MODE,
          location: location,
          path: `${orgId}/${plotId}/${formId}`
        }

        const docData = {
          mode: import.meta.env.VITE_WEB_MODE,
          location: location,
          path: `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}`
        }

        const responseDoc = axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_DOCUMENTS, docData);

        const responseImg = axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_IMAGES, imgData);

        setForms(prevForms => prevForms.filter(form => form.id !== formId));

      } catch (error) {
        showSnackbar('Error deleting form and its submissions. Try again.', 'error');
        Sentry.captureException(error);
      } finally {
        handleDialogClose();
      }
    }
  };

  const isFormAdditionAllowed =
      orgDataLimits.currentTotalData < orgDataLimits.maximumTotalData;

  return (
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        <RoleCheck
            currentAuthUid={currentAuthUid}
            orgId={orgId}
            rolesAllowed={['Admin', 'Owner', 'Contributor', 'Viewer']}
            redirectPage="/account"
            firestore={firestore}
            setUserRole={setUserRole}
        />
        <Typography variant="h3" gutterBottom>
          {t('Plots.Explore')} - {plotName}
        </Typography>
        <Divider
            sx={{ mb: 3, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }}
        />
        <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
        <span>
          <Button
              data-tour="createScoutingEntries"
              variant="contained"
              color="primary"
              onClick={handleCreateFormClick}
              sx={{ mb: 3 }}
              disabled={!isFormAdditionAllowed ||!(userRole === 'Admin' || userRole === 'Owner')}
          >
            {t('Plots.CreateScoutingButton')}
          </Button>
        </span>
        </Tooltip>
        <Grid container spacing={3}>
          {forms.map((form) => (
              <Grid item xs={12} sm={12} md={12} key={form.id}>
                <Card
                    elevation={5}
                    sx={{ border: '3px solid', borderColor: 'primary.border' }}
                >
                  <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <Grid container direction="column" alignItems="center">
                          <Typography variant="h5" align="center" sx={{ fontSize: '1.4rem' }}>
                            {form.formName}
                          </Typography>
                          <Grid
                              container
                              justifyContent="center"
                              sx={{ mt: 1 }}
                              direction={isMobile ? 'column' : 'row'}
                          >
                            <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
                          <span>
                            <Button
                                variant="outlined"
                                onClick={() => handleEnterDataClick(form.id)}
                                sx={{
                                  mt: 1,
                                  color: 'primary.main',
                                  backgroundColor: 'secondary.main',
                                  mr: isMobile ? 0 : 1,
                                }}
                                disabled={
                                  !isFormAdditionAllowed ||
                                  !(userRole === 'Admin' ||
                                      userRole === 'Owner' ||
                                      userRole === 'Contributor')
                                }
                            >
                              {t('General.EnterData')}
                            </Button>
                          </span>
                            </Tooltip>
                            <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
                          <span>
                            <Button
                                variant="outlined"
                                onClick={() => handleViewDataClick(form.id)}
                                sx={{
                                  mt: 1,
                                  ml: isMobile ? 0 : 1,
                                  backgroundColor: 'secondary.main',
                                }}
                                disabled={
                                  !isFormAdditionAllowed ||
                                  !(userRole === 'Admin' ||
                                      userRole === 'Owner' ||
                                      userRole === 'Contributor')
                                }
                            >
                              {t('General.ViewData')}
                            </Button>
                          </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                          item
                          xs={1}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            pr: 1,
                            pt: isMobile ? 5 : 0,
                          }}
                      >
                        <Tooltip title={t('Plots.EditScouting')}>
                      <span>
                        <Button
                            onClick={() => handleEditFormClick(form.id)}
                            disabled={
                              !isFormAdditionAllowed ||
                              !(userRole === 'Admin' || userRole === 'Owner')
                            }
                        >
                          <EditIcon />
                        </Button>
                      </span>
                        </Tooltip>
                        <Tooltip title={t('Plots.DeleteScouting')}>
                      <span>
                        <Button
                            onClick={() => handleDeleteFormClick(form.id, form.formName)}
                            disabled={!(userRole === 'Admin' || userRole === 'Owner')}
                        >
                          <DeleteIcon />
                        </Button>
                        <DeleteDialogBox
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            onConfirm={handleDeleteForm}
                            itemName={selectedItem.name}
                        />
                      </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
          ))}
        </Grid>
        <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
        />
      </Container>
  );
}

export default ExploreMyPlot;
