import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import {
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  increment,
  writeBatch,
  Timestamp,
} from 'firebase/firestore';
import RenderPlotForDataEntry from '../../components/plots/RenderPlotForDataEntry';
import { getFirestoreLocation, auth } from '../../firebaseConfig';
import { useSnackbar } from '../../components/SnackbarContext';
import { sendNotification } from '../../utils/SendNotification';
import CellSubmissionHistoryManager from '../../components/plots/CellSubmissionHistory';
import RoleCheck from '../../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

function PlotDataEntry() {
  const { orgId, plotId, formId, location } = useParams();
  const [plotDetails, setPlotDetails] = useState(null);
  const [formDetails, setFormDetails] = useState(null);
  const [answers, setAnswers] = useState({});
  const [selectedCells, setSelectedCells] = useState([]);
  const [weekNum, setWeekNum] = useState(null); // Initially null
  const [savedWeekNum, setSavedWeekNum] = useState(null);
  const [yearNum, setYearNum] = useState(null); // Initially null
  const [savedYearNum, setSavedYearNum] = useState(null);
  const [userRole, setUserRole] = useState('');
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [orgName, setOrgName] = useState('');
  const [comment, setComment] = useState('');
  const [orgDataLimits, setOrgDataLimits] = useState({
    currentTotalData: 0,
    maximumTotalData: 0,
    currentWrites: 0,
    maximumWrites: 0,
  });
  const { t } = useTranslation();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const { firestore: defFirestore, storageBucket: defStorageBucket } = getFirestoreLocation('default');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [removedCells, setRemovedCells] = useState([]);
  const [submittedCells, setSubmittedCells] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const saveWeekAndYear = async () => {
    try {
      const formDoc = doc(
          firestore,
          `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}`
      );
      await setDoc(
          formDoc,
          { weekNum: Number(weekNum), yearNum: Number(yearNum) },
          { merge: true }
      );
      setSavedWeekNum(weekNum);
      setSavedYearNum(yearNum);
      showSnackbar('Week and year saved successfully.', 'success');
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const fetchPlotDetails = async () => {
      try {
        const plotDoc = doc(firestore, 'organizations', orgId, 'plots', plotId);
        const plotSnapshot = await getDoc(plotDoc);
    
        if (plotSnapshot.exists()) {
          const plotData = plotSnapshot.data();
          setPlotDetails(plotData);
          if (plotData.removedCells) {
            setRemovedCells(plotData.removedCells);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
  
    const fetchFormDetails = async () => {
      try {
        const formDoc = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
        const formSnapshot = await getDoc(formDoc);
    
        if (formSnapshot.exists()) {
          const formData = formSnapshot.data();
          setFormDetails(formData);
  
          // Set saved week and year numbers, along with current week and year states
          if (formData.weekNum) {
            setSavedWeekNum(formData.weekNum);
            setWeekNum(Number(formData.weekNum));  // Set initial weekNum to savedWeekNum
          }
          if (formData.yearNum) {
            setSavedYearNum(formData.yearNum);
            setYearNum(Number(formData.yearNum));  // Set initial yearNum to savedYearNum
          }

          const initialAnswers = formData.questions.reduce((acc, question) => {
            acc[question.id] = '';
            return acc;
          }, {});
          setAnswers(initialAnswers);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchOrgDataLimits = async () => {
      try {
        const orgDocRef = doc(firestore, 'organizations', orgId);
        const orgDoc = await getDoc(orgDocRef);
        if (orgDoc.exists()) {
          const { currentTotalData, maximumTotalData, currentWrites, maximumWrites, canUseCamera, name } = orgDoc.data();
          setOrgName(name);
          setOrgDataLimits({ currentTotalData, maximumTotalData, currentWrites, maximumWrites });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
  
    fetchPlotDetails();
    fetchFormDetails();
    fetchOrgDataLimits();
  }, [orgId, plotId, formId, firestore]);
  
  const handleCellSubmissionComplete = useCallback((submittedCells) => {
    setSubmittedCells(submittedCells);
    setIsLoading(false); // Mark loading as complete
  }, []);

  const handleInputChange = (questionId, value) => {
    if (questionId === 'weekNum' || questionId === 'yearNum') {
      setAnswers((prev) => ({ ...prev, [questionId]: Number(value) }));
    } else {
      setAnswers((prev) => ({ ...prev, [questionId]: value }));
    }
  };

  const handleClearInput = (questionId) => {
    setAnswers((prev) => ({ ...prev, [questionId]: '' }));
  };

  const renderQuestionInput = (question) => {
    if (question.dataType === 'dropdown' && question.options) {
      return (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FormControl sx={{ width: isMobile ? '100%' : '15vw', ml: isMobile ? 0 : 4 }}>
              <InputLabel id={`question-label-${question.id}`}>
                {question.text}
              </InputLabel>
              <Select
                  labelId={`question-label-${question.id}`}
                  id={`question-${question.id}`}
                  value={answers[question.id] || ''}
                  label={question.text}
                  onChange={(e) => handleInputChange(question.id, e.target.value)}
              >
                {question.options
                    .filter((option) => !option.isDeleted)
                    .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.text}
                        </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <IconButton
                onClick={() => handleClearInput(question.id)}
                aria-label="clear"
                size="large"
            >
              <Clear />
            </IconButton>
          </Box>
      );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
              type={question.dataType === 'decimal' ? 'number' : 'text'}
              label={question.text}
              fullWidth
              value={answers[question.id] || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
          />
          <IconButton
              onClick={() => handleClearInput(question.id)}
              aria-label="clear"
              size="large"
          >
            <Clear />
          </IconButton>
        </Box>
    );
  };

  const handleCellClick = (row, column) => {
    const newCell = { row, column };
    setSelectedCells((prevCells) => {
      const isCellAlreadySelected = prevCells.some(
          (cell) => cell.row === row && cell.column === column
      );
      if (isCellAlreadySelected) {
        return prevCells.filter(
            (cell) => cell.row !== row || cell.column !== column
        );
      } else {
        return [...prevCells, newCell];
      }
    });
  };

  const isSubmissionValid = () => {
    const pestType = answers['question-pest-type'];
    const pestLevel = answers['question-pest-level'];
    const diseaseType = answers['question-disease-type'];
    const diseaseLevel = answers['question-disease-level'];

    const allDropdownsClear =
        !pestType && !pestLevel && !diseaseType && !diseaseLevel;

    return (
        !allDropdownsClear &&
        ((pestType && pestLevel) || (!pestType && !pestLevel)) &&
        ((diseaseType && diseaseLevel) || (!diseaseType && !diseaseLevel))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSubmissionValid()) {
      showSnackbar(
          'Both pest type and level or both disease type and level must be selected, and all four dropdowns cannot be clear.',
          'error'
      );
      return;
    }
    setIsLoading(true);

    const formRef = doc(firestore, `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}`);
    const formSnap = await getDoc(formRef);
    const formData = formSnap.data();

    if (formData.deleted === null || formData.deleted === true) {
      showSnackbar(t('General.DocumentDoesNotExist'), 'error');
      return;
    }


    const orgDocRef = doc(firestore, 'organizations', orgId);
    const orgDocSnap = await getDoc(orgDocRef);
    const orgData = orgDocSnap.data();
    const numSelectedCells = selectedCells.length;

    // Check data limits
    if (orgData.currentTotalData >= orgData.maximumTotalData) {
      showSnackbar(t('General.MaxTotalData'), 'error');
      setIsLoading(false);
      return;
    }

    if (orgData.currentWrites + numSelectedCells > orgData.maximumWrites) {
      showSnackbar(t('General.MaxMonthly'), 'error');
      setIsLoading(false);
      return;
    }

    let submissionCount = 0;

    const batch = writeBatch(firestore);

    try {
      for (const cell of selectedCells) {
        const submissionTime = new Date();
        const yearDocPath = doc(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots',
            formId,
            'years',
            `${savedYearNum}`
        )
        const weekDocPath = doc(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots',
            formId,
            'years',
            `${yearNum}`,
            'weeks',
            `${savedWeekNum}`
        );
        const adjustedColumn =
            plotDetails.walkwayPosition > 0 && cell.column >= plotDetails.walkwayPosition
                ? cell.column - 1
                : cell.column;
        const submissionsPath = `years/${savedYearNum}/weeks/${savedWeekNum}/submissions`;
        const submissionCollection = collection(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots',
            formId,
            submissionsPath
        );

        batch.set(
            yearDocPath,
            { lastUpdated: submissionTime },
            { merge: true }
        );

        batch.set(
            weekDocPath,
            { lastUpdated: submissionTime },
            { merge: true }
        );

        const getOptionById = (questionId, id) => {
          const question = formDetails.questions.find((q) => q.id === questionId);
          return question.options.find((o) => o.id === id);
        };

        const getTextById = (questionId, id) => {
          const question = formDetails.questions.find((q) => q.id === questionId);
          const option = question.options.find((o) => o.id === id);
          return option ? option.text : '';
        };

        const pestTypeOption = getOptionById(
            'question-pest-type',
            answers['question-pest-type']
        );
        const diseaseTypeOption = getOptionById(
            'question-disease-type',
            answers['question-disease-type']
        );
        const pestLevelText = getTextById(
            'question-pest-level',
            answers['question-pest-level']
        );
        const diseaseLevelText = getTextById(
            'question-disease-level',
            answers['question-disease-level']
        );

        const submissionData = {
          cellPosition: { row: cell.row, column: adjustedColumn },
          submittedAt: Timestamp.fromDate(new Date()), // Correctly create Firestore timestamp
          diseaseLevel: diseaseLevelText, // Keep as text
          diseaseType: diseaseTypeOption ? diseaseTypeOption.id : '', // Store the ID
          pestLevel: pestLevelText, // Keep as text
          pestType: pestTypeOption ? pestTypeOption.id : '', // Store the ID
          weekNum,
          yearNum,
          pestImage: false,
          diseaseImage: false,
          comment,
        };

        const newSubmissionDocRef = doc(submissionCollection);

        batch.set(newSubmissionDocRef, submissionData);

        const formDocRef = doc(
            firestore,
            'organizations',
            orgId,
            'plots',
            plotId,
            'formsForPlots',
            formId
        );
        if (submissionData.pestType && submissionData.pestLevel) {
          const pestCounterPath = `submissionHistory.${yearNum}.${weekNum}.pestCounters.${submissionData.pestType}.${submissionData.pestLevel}`;
          batch.update(formDocRef, { [pestCounterPath]: increment(1) });
          submissionCount++;
        }

        if (submissionData.diseaseType && submissionData.diseaseLevel) {
          const diseaseCounterPath = `submissionHistory.${yearNum}.${weekNum}.diseaseCounters.${submissionData.diseaseType}.${submissionData.diseaseLevel}`;
          batch.update(formDocRef, { [diseaseCounterPath]: increment(1) });
          submissionCount++;
        }
      }

      // Increment currentTotalData and numberOfSubmissions by the calculated submissionCount
      batch.update(orgDocRef, {
        currentTotalData: increment(submissionCount),
        currentWrites: increment(submissionCount),
      });

      // Notifications for usage
      const sendNotificationsToAdminsAndOwners = async (message) => {
        // Get users collection inside the organization
        const usersRef = collection(firestore, `organizations/${orgId}/users`);
        const usersSnapshot = await getDocs(usersRef);
        const adminOwnerUsers = [];

        // Filter users with Admin or Owner role
        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          if (userData.role === 'Admin' || userData.role === 'Owner') {
            adminOwnerUsers.push(userDoc.id);
          }
        });

        // Send notification to each Admin/Owner
        for (const userId of adminOwnerUsers) {
          await sendNotification(userId, 'Usage Notice', message, defFirestore);
        }
      };
  
      if ((orgData.currentTotalData + 1) / orgData.maximumTotalData >= 0.5 && orgData.currentTotalData / orgData.maximumTotalData < 0.5) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 50% of its maximum total data.`);
      }
      if ((orgData.currentTotalData + 1) / orgData.maximumTotalData >= 0.9 && orgData.currentTotalData / orgData.maximumTotalData < 0.9) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 90% of its maximum total data.`);
      }
      if ((orgData.currentWrites + 1) / orgData.maximumWrites >= 0.5 && orgData.currentWrites / orgData.maximumWrites < 0.5) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 50% of its submissions for this month.`);
      }
      if ((orgData.currentWrites + 1) / orgData.maximumWrites >= 0.9 && orgData.currentWrites / orgData.maximumWrites < 0.9) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 90% of its submissions for this month.`);
      }

      const formDocRef = doc(
          firestore,
          'organizations',
          orgId,
          'plots',
          plotId,
          'formsForPlots',
          formId
      );

      await updateCellSubmissionHistory(selectedCells);
      batch.update(formDocRef, { numberOfSubmissions: increment(submissionCount) });
      await batch.commit();

      setRefreshKey((prev) => prev + 1);
      setSelectedCells([]);
      setComment('');

      const resetAnswers = formDetails.questions.reduce((acc, question) => {
        acc[question.id] = '';
        return acc;
      }, {});

      setAnswers(resetAnswers);
      showSnackbar(
          'Data submitted successfully for all selected cells, and counters updated!',
          'success'
      );
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  if (!plotDetails || !formDetails) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          Loading...
        </Box>
    );
  }

  const transformArrayToObject = (array) => {
    return array;
  };

  const leftSideLabelsObject = transformArrayToObject(
      plotDetails.leftSideLabels || []
  );

  const rightSideLabelsObject = transformArrayToObject(
      plotDetails.rightSideLabels || []
  );

  const columnLabelsObject = transformArrayToObject(
      plotDetails.columnLabels || []
  );

  const updateCellSubmissionHistory = async (selectedCells) => {
    const weekDocPath = doc(
      firestore,
      `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}/years/${yearNum}/weeks/${weekNum}`
    );
  
    try {
      const weekSnapshot = await getDoc(weekDocPath);
      if (!weekSnapshot.exists()) {
        throw new Error('Week document does not exist.');
      }
  
      const cellSubmissionHistory = weekSnapshot.data().cellSubmissionHistory || Array(25000).fill(0);
  
      selectedCells.forEach(({ row, column }) => { // Destructure row and column
        const globalIndex = row * 500 + column;
  
        const integerIndex = Math.floor(globalIndex / 10);
        const bitPosition = globalIndex % 10;
  
        const binaryString = cellSubmissionHistory[integerIndex].toString(2).padStart(10, '0');
        const updatedBinaryString =
          binaryString.substring(0, bitPosition) + '1' + binaryString.substring(bitPosition + 1);
  
        cellSubmissionHistory[integerIndex] = parseInt(updatedBinaryString, 2);
      });
  
      await setDoc(weekDocPath, { cellSubmissionHistory }, { merge: true });
  
      // Update local submittedCells state
      setSubmittedCells((prevSubmittedCells) =>
        Array.from(new Set([...prevSubmittedCells, ...selectedCells]))
      );
  
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const isSubmitDisabled =
      !savedYearNum ||
      !savedWeekNum ||
      orgDataLimits.currentTotalData >= orgDataLimits.maximumTotalData ||
      !isSubmissionValid() ||
      selectedCells.length === 0;

  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
      >
        <RoleCheck
            currentAuthUid={currentAuthUid}
            orgId={orgId}
            rolesAllowed={['Admin', 'Owner', 'Contributor']}
            redirectPage="/my-plots"
            firestore={firestore}
            setUserRole={setUserRole}
        />
        <Paper
            sx={{
              width: { xs: '100%', md: '20vw' },
              height: { xs: 'auto', md: '83vh' },
              outline: '1px solid grey',
              border: '2px solid #3C3C3C',
              marginTop: { xs: 0, md: '24px' },
              marginLeft: { xs: 0, md: '3px' },
              padding: 2,
              boxSizing: 'border-box',
              overflow: 'auto',
            }}
        >
          <Box sx={{ my: 2, ml: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('Plots.YearWeek')}
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>{t('Plots.Year')}</InputLabel>
              <Select
                  value={yearNum}
                  onChange={(e) => setYearNum(e.target.value)}
              >
                {Array.from({ length: 5 }, (_, i) => (
                    <MenuItem
                        key={new Date().getFullYear() - 2 + i}
                        value={new Date().getFullYear() - 2 + i}
                    >
                      {new Date().getFullYear() - 2 + i}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>{t('Plots.Week')}</InputLabel>
              <Select
                  value={weekNum}
                  onChange={(e) => setWeekNum(e.target.value)}
              >
                {Array.from({ length: 52 }, (_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={saveWeekAndYear}
                sx={{ mb: 2 }}
            >
              {t('Plots.SaveYearWeek')}
            </Button>
            <Typography variant="h6">
              {t('Plots.CurrentYear')}
              {savedYearNum}
            </Typography>
            <Typography variant="h6">
              {t('Plots.CurrentWeek')}
              {savedWeekNum}
            </Typography>
          </Box>
          <Typography variant="h5" gutterBottom sx={{ ml: 1 }}>
            {plotDetails.plotName}
          </Typography>
          <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mb: 2, mx: 2 }}
          >
            {formDetails.questions.map((question) => (
                <Box key={question.id} sx={{ mb: 2 }}>
                  {renderQuestionInput(question)}
                </Box>
            ))}
            <Box sx={{ mx: 2, mb: 2 }}>
              <Typography variant="h6">Comments:</Typography>
              <TextField
                label="comments..."
                multiline
                fullWidth
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
              />
            </Box>
            {(!savedYearNum || !savedWeekNum) && (
                <Typography color="error" sx={{ my: 1 }}>
                  Please save the Week and Year before submitting data.
                </Typography>
            )}
            <Tooltip
                title={isSubmitDisabled ? 'Data limit exceeded or week/year not saved' : ''}
            >
            <span>
              <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: '100%' }}
                  disabled={isSubmitDisabled}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </span>
            </Tooltip>
          </Box>
        </Paper>
        <CellSubmissionHistoryManager
          firestore={firestore}
          orgId={orgId}
          plotId={plotId}
          formId={formId}
          yearNum={yearNum}
          weekNum={weekNum}
          onComplete={handleCellSubmissionComplete}
          refreshKey={refreshKey}
        />
        <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { xs: '100%', md: 'auto' },
            }}
        >
          <RenderPlotForDataEntry
              rows={plotDetails.rows}
              columns={plotDetails.columns}
              walkwayPosition={parseInt(plotDetails.walkwayPosition, 10)}
              leftSideLabels={leftSideLabelsObject}
              rightSideLabels={rightSideLabelsObject}
              columnLabels={columnLabelsObject}
              onCellClick={handleCellClick}
              selectedCells={selectedCells}
              setSelectedCells={setSelectedCells}
              submittedCells={submittedCells} 
              removedCells={removedCells}
          />
        </Box>
      </Box>
  );
}

export default PlotDataEntry;
