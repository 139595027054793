import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Typography, Box, Paper,
} from '@mui/material';
import { doc, updateDoc, onSnapshot, collection, query, getDocs, where } from 'firebase/firestore';
import { useSnackbar } from '../../components/SnackbarContext';
import RenderCreatePlot from '../../components/plots/RenderCreatePlot';
import { getFirestoreLocation, auth } from '../../firebaseConfig';
import RoleCheck from '../../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import restrictedChars from '../../local_data/restricted-chars.json';
import * as Sentry from '@sentry/react';

function EditPlot() {
  const { plotId, orgId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [userRole, setUserRole] = useState('');
  const [plotData, setPlotData] = useState({
    selectedOrg: '',
    plotName: '',
    rows: 0,
    columns: 0,
    walkwayPosition: 0,
    leftSideLabels: [],
    rightSideLabels: [],
    columnLabels: [],
  });
  const [clickedCells, setClickedCells] = useState({});
  const [plotNameError, setPlotNameError] = useState(false); // Change to boolean
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();

  useEffect(() => {
    try {
      const plotDoc = doc(firestore, 'organizations', orgId, 'plots', plotId);

      const unsubscribe = onSnapshot(plotDoc, (docSnapshot) => {
        const data = docSnapshot.data();
        if (data) {
          // Convert the removedCells array to clickedCells object
          const removedCells = data.removedCells || [];
          const clickedCellsFromFirestore = {};
          removedCells.forEach(({ rowIndex, columnIndex }) => {
            clickedCellsFromFirestore[`${rowIndex}-${columnIndex}`] = true;
          });

          setPlotData({
            ...data,
            rows: parseInt(data.rows, 10) || 0,
            columns: parseInt(data.columns, 10) || 0,
            walkwayPosition: parseInt(data.walkwayPosition, 10) || 0,
            leftSideLabels: Array.isArray(data.leftSideLabels) ? data.leftSideLabels : Object.values(data.leftSideLabels),
            rightSideLabels: Array.isArray(data.rightSideLabels) ? data.rightSideLabels : Object.values(data.rightSideLabels),
            columnLabels: Array.isArray(data.columnLabels) ? data.columnLabels : Object.values(data.columnLabels),
          });

          setClickedCells(clickedCellsFromFirestore);
        }
      });

      return () => unsubscribe();
    } catch (error) {
      Sentry.captureException(error);
    }

  }, [plotId, orgId]);

  const setColumnLabels = (newLabels) => {
    setPlotData((prevData) => ({
      ...prevData,
      columnLabels: Array.isArray(newLabels) ? newLabels : Object.values(newLabels),
    }));
  };

  const handleColumnLabelChange = (e, columnIndex) => {
    const newLabel = e.target.value.slice(0, 4); // Limit to 4 characters
    const updatedLabels = [...plotData.columnLabels];
    updatedLabels[columnIndex] = newLabel;
    setColumnLabels(updatedLabels);
  };

  const setLeftSideLabels = (newLabels) => {
    setPlotData((prevData) => ({
      ...prevData,
      leftSideLabels: Array.isArray(newLabels) ? newLabels : Object.values(newLabels),
    }));
  };

  const setRightSideLabels = (newLabels) => {
    setPlotData((prevData) => ({
      ...prevData,
      rightSideLabels: Array.isArray(newLabels) ? newLabels : Object.values(newLabels),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'rows' || name === 'columns' || name === 'walkwayPosition') {
      newValue = parseInt(value, 10) || 0;

      if (newValue < 0) {
        newValue = 0; // Ensure non-negative numbers
      }

      if (newValue > 1000 && (name === 'rows' || name === 'columns')) {
        showSnackbar(`Number of ${name.charAt(0) + name.slice(1)} cannot be more than 1000.`, 'error');
        return; // Prevent state update if rows or columns are greater than 1000
      }
    }

    if (name === 'walkwayPosition' && newValue > (plotData.columns + 1)) {
      showSnackbar(t('Snackbar.WalkwayPositionMax'), 'error');
      return;
    }

    setPlotData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  };

  const handleLeftLabelChange = (e, rowIndex) => {
    const newLabel = e.target.value;
    const updatedLabels = [...plotData.leftSideLabels];
    updatedLabels[rowIndex] = newLabel;
    setLeftSideLabels(updatedLabels);
  };

  const handleRightLabelChange = (e, rowIndex) => {
    const newLabel = e.target.value;
    const updatedLabels = [...plotData.rightSideLabels];
    updatedLabels[rowIndex] = newLabel;
    setRightSideLabels(updatedLabels);
  };

  const validatePlotName = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const handlePlotNameChange = (e) => {
    const newName = e.target.value;
    if (newName.length > 63) {
      setPlotNameError(true); // Set as boolean
    } else if (!validatePlotName(newName)) {
      setPlotNameError(true); // Set as boolean
    } else {
      setPlotNameError(false); // Set as boolean
    }
    setPlotData(prevData => ({
      ...prevData,
      plotName: newName
    }));
  };

  const handleSaveChanges = async () => {
    const trimmedPlotName = plotData.plotName.trim(); // Trim the plot name
    if (!trimmedPlotName) {
      showSnackbar(t('Snackbar.PlotNameEmpty'), 'error');
      return;
    }

    if (!validatePlotName(trimmedPlotName)) {
      showSnackbar(t('Snackbar.PlotNameRestrictedCharsMessage'), 'error');
      return;
    }

    if (trimmedPlotName !== plotId) {
      const plotsCollection = collection(firestore, 'organizations', orgId, 'plots');
      const nameCheckQuery = query(plotsCollection, where("plotName", "==", trimmedPlotName));
      const idCheckQuery = query(plotsCollection, where("__name__", "==", trimmedPlotName));

      try {
        const [nameSnapshot, idSnapshot] = await Promise.all([
          getDocs(nameCheckQuery),
          getDocs(idCheckQuery)
        ]);

        const isNameDuplicate = nameSnapshot.docs.some(doc => doc.id !== plotId);
        const isIdDuplicate = idSnapshot.docs.length > 0;

        if (isNameDuplicate || isIdDuplicate) {
          showSnackbar(t('Snackbar.PlotNameExists'), 'error');
          return;
        }
      } catch (error) {
        showSnackbar('Failed to check plot uniqueness. Please try again.', 'error');
        Sentry.captureException(error);
        return;
      }
    }

    // Convert clickedCells back to an array of objects like { rowIndex, columnIndex }
    const removedCells = Object.entries(clickedCells)
      .filter(([key, value]) => value)
      .map(([key]) => {
        const [rowIndex, columnIndex] = key.split('-').map(Number);
        return { rowIndex, columnIndex };
      });

    // Proceed with updating the document if no duplicates are found
    const plotDoc = doc(firestore, 'organizations', orgId, 'plots', plotId);
    updateDoc(plotDoc, {
      ...plotData,
      plotName: trimmedPlotName,
      leftSideLabels: Array.isArray(plotData.leftSideLabels) ? plotData.leftSideLabels : Object.values(plotData.leftSideLabels),
      rightSideLabels: Array.isArray(plotData.rightSideLabels) ? plotData.rightSideLabels : Object.values(plotData.rightSideLabels),
      columnLabels: Array.isArray(plotData.columnLabels) ? plotData.columnLabels : Object.values(plotData.columnLabels),
      removedCells // Update the removed cells
    })
      .then(() => {
        showSnackbar(t('Snackbar.PlotUpdated'), 'success');
        navigate('/home?activePage=plots');
      })
      .catch((error) => {
        showSnackbar('Failed to update plot.', 'error');
      });
  };

  const isSaveButtonDisabled = !plotData.plotName || plotNameError;

  return (
    <Box sx={{ display: 'flex', paddingTop: '15px'}}>
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Paper
        variant="permanent"
        sx={{
          width: '20vw',
          height: '60vh',
          flexShrink: 1,
          border: '2px solid #3C3C3C',
          overflow: 'auto',
          marginTop:'24px',
          marginLeft:'16px'
        }}
      >
        <Box sx={{ padding: 1}}>
          <Typography variant="h5" gutterBottom>
            {t('Plots.Edit')}
          </Typography>

          <TextField
            label={t('Plots.Name')}
            type="text"
            name="plotName"
            placeholder="Enter Plot Name"
            value={plotData.plotName}
            onChange={handlePlotNameChange}
            inputProps={{ maxLength: 63 }}
            fullWidth
            margin="normal"
            helperText={plotNameError ? 'Invalid Chars' : ''}
            error={plotNameError}
            sx={{
              marginBottom: 2,
            }}
          />

          <Box sx={{
            display: 'flex', gap: 2, mb: 1, flexDirection: 'column',
          }}
          >
            <TextField
              label={t('Plots.NumberOfRows')}
              type="number"
              name="rows"
              placeholder="Number of Rows"
              value={plotData.rows}
              onChange={handleInputChange}
            />

            <TextField
              label={t('Plots.NumberOfColumns')}
              type="number"
              name="columns"
              placeholder="Number of Columns"
              value={plotData.columns}
              onChange={handleInputChange}
            />

            <TextField
              label={t('Plots.WalkwayPosition')}
              type="number"
              name="walkwayPosition"
              placeholder="Walkway Position"
              value={plotData.walkwayPosition}
              onChange={handleInputChange}
            />
          </Box>

          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveChanges}
            disabled={isSaveButtonDisabled}
          >
            {t('General.SaveChanges')}
          </Button>
          <Typography>
            {t('Plots.RemoveCellsMessage')}
          </Typography>
        </Box>
      </Paper>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <RenderCreatePlot
          rows={plotData.rows}
          columns={plotData.columns}
          walkwayPosition={plotData.walkwayPosition}
          leftSideLabels={plotData.leftSideLabels}
          rightSideLabels={plotData.rightSideLabels}
          columnLabels={plotData.columnLabels}
          onLeftLabelChange={handleLeftLabelChange}
          onRightLabelChange={handleRightLabelChange}
          onColumnLabelChange={handleColumnLabelChange}
          setLeftSideLabels={setLeftSideLabels}
          setRightSideLabels={setRightSideLabels}
          setColumnLabels={setColumnLabels}
          clickedCells={clickedCells} // Pass clickedCells to RenderCreatePlot
          setClickedCells={setClickedCells} // Pass setClickedCells to RenderCreatePlot
        />
      </Box>
    </Box>
  );
}

export default EditPlot;
