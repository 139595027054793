import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField, Button, Box, Container, Grid, Checkbox, FormControlLabel, Typography,
} from '@mui/material';
import { doc, setDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { PlotQuestion } from '../../components/plots/PlotQuestion';
import { useSnackbar } from '../../components/SnackbarContext';
import Tour from 'reactour';
import useOrgFirestore from '../../hooks/useOrgFirestore';
import { auth } from '../../firebaseConfig';
import RoleCheck from '../../utils/roleCheck';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import restrictedChars from '../../local_data/restricted-chars.json';
import * as Sentry from '@sentry/react';

function CreateFormForPlot() {
  const { orgId, plotId } = useParams();
  const { firestore, location } = useOrgFirestore(orgId);
  const navigate = useNavigate();
  const [formName, setFormName] = useState('');
  const [formNameError, setFormNameError] = useState(false); // Add form name error state
  const [userRole, setUserRole] = useState('');
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('createFormsForPlotsTourSeen') === 'true');
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0, currentWrites: 0, maximumWrites: 0 });
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();

  useEffect(() => {
    setIsTourOpen(!tourSeen);
  }, [tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('createFormsForPlotsTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="formName"]',
      content: t('Plots.ReactTour.Desc.7'),
    },
    {
      selector: '[data-tour="pestDiseaseOptions"]',
      content: t('Plots.ReactTour.Desc.8'),
    },
    {
      selector: '[data-tour="submit"]',
      content: t('Plots.ReactTour.Desc.9'),
    },
    {
      selector: '',
      content: () => (
        <FormControlLabel
          control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
          label={t("General.DoNotShow")}
        />
      ),
    },
  ];

  const initialQuestions = [
    {
      text: 'Pest Type',
      dataType: 'dropdown',
      mandatory: true,
      options: [
        { text: 'Aphids', id: uuidv4(), isDeleted: false },
        { text: 'Whiteflies', id: uuidv4(), isDeleted: false },
        { text: 'Caterpillars', id: uuidv4(), isDeleted: false },
        { text: 'Spider Mites', id: uuidv4(), isDeleted: false },
        { text: 'Stink Bugs', id: uuidv4(), isDeleted: false },
        { text: 'Thrips', id: uuidv4(), isDeleted: false }
      ],
      id: 'question-pest-type',
      editableOptions: true,
      editableText: false,
      visible: true,
    },
    {
      text: 'Pest Level',
      dataType: 'dropdown',
      mandatory: true,
      options: [
        { text: 'Low', id: uuidv4(), isDeleted: false },
        { text: 'Medium', id: uuidv4(), isDeleted: false },
        { text: 'High', id: uuidv4(), isDeleted: false }
      ],
      id: 'question-pest-level',
      editableOptions: false,
      editableText: false,
      visible: false,
    },
    {
      text: 'Disease Type',
      dataType: 'dropdown',
      mandatory: true,
      options: [
        { text: 'Rugose', id: uuidv4(), isDeleted: false },
        { text: 'Botrytis', id: uuidv4(), isDeleted: false },
        { text: 'Powdery Mildew', id: uuidv4(), isDeleted: false },
        { text: 'Downy Mildew', id: uuidv4(), isDeleted: false },
        { text: 'Late Blight', id: uuidv4(), isDeleted: false },
        { text: 'Fusarium Wilt', id: uuidv4(), isDeleted: false }
      ],
      id: 'question-disease-type',
      editableOptions: true,
      editableText: false,
      visible: true,
    },
    {
      text: 'Disease Level',
      dataType: 'dropdown',
      mandatory: true,
      options: [
        { text: 'Low', id: uuidv4(), isDeleted: false },
        { text: 'Medium', id: uuidv4(), isDeleted: false },
        { text: 'High', id: uuidv4(), isDeleted: false }
      ],
      id: 'question-disease-level',
      editableOptions: false,
      editableText: false,
      visible: false,
    },
  ];

  const [questions, setQuestions] = useState(initialQuestions);

  const updateQuestion = (id, questionData) => {
    setQuestions((prevQuestions) => prevQuestions.map((q) => {
      if (q.id === id) {
        return { ...q, ...questionData };
      }
      return q;
    }));
  };

  const validateFormName = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const handleFormNameChange = (e) => {
    const newName = e.target.value;
    if (newName.length > 63) {
      setFormNameError(true); // Set as boolean
    } else if (!validateFormName(newName)) {
      setFormNameError(true); // Set as boolean
    } else {
      setFormNameError(false); // Set as boolean
    }
    setFormName(newName);
  };

  const fetchOrgDataLimits = async () => {
    if (firestore && orgId) {
      const orgDocRef = doc(firestore, 'organizations', orgId);
      const orgDoc = await getDoc(orgDocRef);
      if (orgDoc.exists()) {
        const { currentTotalData, maximumTotalData, currentWrites, maximumWrites } = orgDoc.data();
        setOrgDataLimits({ currentTotalData, maximumTotalData, currentWrites, maximumWrites });
      }
    }
  };

  useEffect(() => {
    fetchOrgDataLimits();
  }, [firestore, orgId]);

  const handleSubmit = async () => {
    try {
      const trimmedFormName = formName.trim();  // Trim the form name
      if (!trimmedFormName) {
        showSnackbar(t('Snackbar.FormNameEmpty'), 'error');
        return;
      }

      if (!validateFormName(trimmedFormName)) {
        showSnackbar(t('Snackbar.RestrictedCharsMessage'), 'error');
        return;
      }

      if (orgDataLimits.currentTotalData > orgDataLimits.maximumTotalData) {
        showSnackbar(t('General.MaxTotalData'), 'error');
        return;
      }

      if (orgDataLimits.currentWrites > orgDataLimits.maximumWrites) {
        showSnackbar(t('General.MaxMonthly'), 'error');
        return;
      }

      const formsCollection = collection(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots');

      // Prepare queries to check both the existence of the same formName attribute and formName as document ID
      const formNameAttributeQuery = query(formsCollection, where("formName", "==", trimmedFormName));
      const formIdQuery = query(formsCollection, where("__name__", "==", trimmedFormName)); // '__name__' accesses the document ID

      const [formNameSnapshot, formIdSnapshot] = await Promise.all([
        getDocs(formNameAttributeQuery),
        getDocs(formIdQuery)
      ]);

      // Check if there are any documents with the same formName attribute or document ID
      if (!formNameSnapshot.empty || !formIdSnapshot.empty) {
        showSnackbar(t('Snackbar.FormNameExists'), 'error');
        return;
      }

      const newFormData = { 
        questions, 
        formName: trimmedFormName, 
        numberOfSubmissions: 0,
        deleted: false
      };
      const formId = uuidv4();  // Generate a UUID for the form ID
      const plotFormDoc = doc(formsCollection, formId);  // Use the UUID as the document ID

      await setDoc(plotFormDoc, newFormData);
      showSnackbar(t('Snackbar.FormSaved'), 'success');
      navigate(`/explore-my-plot/${orgId}/${location}/${plotId}`);
    } catch (error) {
      showSnackbar('Failed to save form, try again', 'error');
      Sentry.captureException(error);
    }
  };

  const isCreateButtonDisabled = !formName || formNameError; // Add form name error to disable condition

  return (
    <Container maxWidth="md">
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('General.CreateScouting')}
        </Typography>
        <TextField
          data-tour="formName"
          fullWidth
          label={t('Plots.NameOfScouting')}
          variant="outlined"
          value={formName}
          onChange={handleFormNameChange} // Change to handleFormNameChange
          inputProps={{ maxLength: 63 }}
          helperText={formNameError ? 'Invalid Form Name' : ''} // Add helper text
          error={formNameError} // Add error state
          sx={{ mb: 2 }}
        />
        <Grid data-tour="pestDiseaseOptions" container spacing={2}>
          {questions.filter(q => q.visible).map((question) => (
            <Grid item xs={12} md={6} key={question.id}>
              <PlotQuestion
                id={question.id} // Pass id here
                index={question.id} // Pass index as id
                data={question}
                updateQuestion={updateQuestion}
                editableText={question.editableText}
              />
            </Grid>
          ))}
        </Grid>
        <Button
          data-tour="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          disabled={isCreateButtonDisabled} // Disable button based on boolean error states
        >
          {t('Plots.CreateScoutingButton')}
        </Button>
      </Box>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </Container>
  );
}

export default CreateFormForPlot;
