import { useEffect } from 'react';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';

function CellSubmissionHistoryManager({ 
  firestore, orgId, plotId, formId, yearNum, weekNum, onComplete, refreshKey
}) {

  useEffect(() => {
    const extractCoordinatesFromIntegerArray = (integerArray, columns) => {
      const cellsSubmitted = [];
      integerArray.forEach((intValue, startIndex) => {
        const binaryString = intValue.toString(2).padStart(10, '0');
        binaryString.split('').forEach((bit, bitIndex) => {
          if (bit === '1') {
            const globalIndex = startIndex * 10 + bitIndex;
            const rowIndex = Math.floor(globalIndex / columns);
            const columnIndex = globalIndex % columns;
            cellsSubmitted.push({ rowIndex, columnIndex });
          }
        });
      });
      return cellsSubmitted;
    };

    const fetchOrCreateCellSubmissionHistory = async () => {
      const weekDocPath = doc(firestore, `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}/years/${yearNum}/weeks/${weekNum}`);
      const yearDocPath = doc(firestore, `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}/years/${yearNum}`);

      try {
        const weekSnapshot = await getDoc(weekDocPath);

        if (weekSnapshot.exists() && weekSnapshot.data().cellSubmissionHistory) {
          const integerArray = weekSnapshot.data().cellSubmissionHistory;
          const cellsSubmitted = extractCoordinatesFromIntegerArray(integerArray, 500);
          onComplete(cellsSubmitted);
          return;
        }

        const newBinaryArray = Array(250000).fill(0);
        const submissionsCollection = collection(firestore, `organizations/${orgId}/plots/${plotId}/formsForPlots/${formId}/years/${yearNum}/weeks/${weekNum}/submissions`);
        const submissionsSnapshot = await getDocs(submissionsCollection);

        submissionsSnapshot.forEach((doc) => {
          const submission = doc.data();
          const { cellPosition } = submission;
          if (cellPosition) {
            const index = cellPosition.row * 500 + cellPosition.column;
            newBinaryArray[index] = 1;
          }
        });

        const integerArray = [];
        for (let i = 0; i < newBinaryArray.length; i += 10) {
          const binaryGroup = newBinaryArray.slice(i, i + 10);
          const intValue = parseInt(binaryGroup.join(''), 2);
          integerArray.push(intValue);
        }

        const cellsSubmitted = extractCoordinatesFromIntegerArray(integerArray, 500);
        await setDoc(weekDocPath, { cellSubmissionHistory: integerArray }, { merge: true });
        // Add lastUpdated field to the year document
        const currentTime = new Date();
        await setDoc(yearDocPath, { lastUpdated: currentTime }, { merge: true });
        onComplete(cellsSubmitted);
      } catch (error) {
        console.error('Error fetching or creating cell submission history:', error);
      }
    };

    if (orgId && plotId && formId && yearNum && weekNum) {
      fetchOrCreateCellSubmissionHistory();
    }
  }, [firestore, orgId, plotId, formId, yearNum, weekNum, onComplete, refreshKey]);

  return null; // Render nothing once loading is complete
}

export default CellSubmissionHistoryManager;
