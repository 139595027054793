import React, { useState, useEffect } from 'react';
import { Modal, Box, Switch, Typography, FormControlLabel, Grid, Button } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from '../components/SnackbarContext';

const PermissionsModal = ({ item, isOpen, onClose, isAdminOrOwner, userData, firestore, orgId, onPermissionsUpdate }) => {
  const [permissions, setPermissions] = useState({});
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      // Initialize permissions with default values
      const initialPermissions = {};
      Object.keys(userData).forEach(userId => {
        const role = userData[userId]?.role || 'Unknown';
        if (role === 'Admin' || role === 'Owner') {
          initialPermissions[userId] = true; // Admins/Owners always have permissions
        } else {
          initialPermissions[userId] = item.userPermissions?.[userId] || false; // Default to false if undefined
        }
      });

      setPermissions(initialPermissions);
    }
  }, [item, userData]);

  if (!isAdminOrOwner) return null;

  const handleToggleChange = (userId, role) => {
    if (role === 'Admin' || role === 'Owner') return; // Don't allow changes for Admins/Owners

    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [userId]: !prevPermissions[userId],
    }));
  };

  const savePermissions = async () => {
    try {
      const itemDocRef = doc(firestore, `organizations/${orgId}/${item.type}/${item.id}`);
      await updateDoc(itemDocRef, { userPermissions: permissions });

      if (onPermissionsUpdate) {
        onPermissionsUpdate(item.id, permissions);
      }

      showSnackbar('Permissions updated successfully', 'success');
      onClose();
    } catch (error) {
      console.error('Error updating permissions: ', error);
      showSnackbar('Failed to update permissions. Please try again.', 'error');
    }
  };

  return (
      <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h6" id="modal-title">
            {item?.name || 'Manage Permissions'}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, mb: 2 }}>
            Manage permissions for each user:
          </Typography>

          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', minWidth: 150 }}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', minWidth: 100 }}>
              <Typography variant="subtitle2">Role</Typography>
            </Grid>
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
              <Typography variant="subtitle2">Permission</Typography>
            </Grid>
          </Grid>

          {Object.entries(userData).map(([userId, user]) => {
            const role = user.role || 'Unknown';
            const hasPermission = permissions[userId] ?? false;
            const isDisabled = role === 'Admin' || role === 'Owner';

            return (
                <Grid container spacing={2} alignItems="center" key={userId}>
                  <Grid item xs sx={{ display: 'flex', alignItems: 'center', minWidth: 150 }}>
                    <Typography noWrap>{user.email || 'Unknown'}</Typography>
                  </Grid>
                  <Grid item xs sx={{ display: 'flex', alignItems: 'center', minWidth: 100 }}>
                    <Typography noWrap>{role}</Typography>
                  </Grid>
                  <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
                    <FormControlLabel
                        control={
                          <Switch
                              checked={isDisabled ? true : hasPermission}
                              onChange={() => handleToggleChange(userId, role)}
                              disabled={isDisabled}
                          />
                        }
                        label=""
                    />
                  </Grid>
                </Grid>
            );
          })}

          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={savePermissions}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
  );
};

// Example style for the modal box
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default PermissionsModal;
