import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  Button,
  IconButton,
  Modal,
  Box,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import * as Sentry from '@sentry/react';

const CellModal = ({
  selectedCellData,
  setSelectedCellData,
  getOptionTextById,
  deleteSubmission,
  t,
  showSnackbar,
  canUseCamera,
  storageBucket,
  app,
  orgId,
  plotId,
  formId,
  selectedYear,
  selectedWeek
}) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');

  useEffect(() => {
    if (selectedCellData) {
      setImageUrls([]);
      setCurrentImageIndex(0);
      setModalOpen(false);
      setCommentModalOpen(false);
      setSelectedComment('');
    }
  }, [selectedCellData]);

  const handleShowImage = async (docId, type, hasImage, imageTracker) => {
    if (!hasImage) return;
    const imageType = type === 'pest' ? 'pests' : 'diseases';
    const storage = getStorage(app, storageBucket);
    const urls = [];
    console.log(imageTracker)
    if (imageTracker[0]) {
      try {
        const noSuffixPath = `${storageBucket}/${orgId}/${plotId}/${formId}/${selectedYear}/${selectedWeek}/${imageType}/${docId}.jpeg`;
        const noSuffixRef = ref(storage, noSuffixPath);
        const url = await getDownloadURL(noSuffixRef);
        urls.push(url);
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    for (let i = 2; i < (imageTracker.length + 1); i++) {
      if (imageTracker[i - 1]) {
        try {
          const suffixedPath = `${storageBucket}/${orgId}/${plotId}/${formId}/${selectedYear}/${selectedWeek}/${imageType}/${docId}_${i}.jpeg`;
          const suffixedRef = ref(storage, suffixedPath);
          const url = await getDownloadURL(suffixedRef);
          urls.push(url);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }

    if (urls.length > 0) {
      setImageUrls(urls);
      setCurrentImageIndex(0);
      setModalOpen(true);
    } else {
      setModalOpen(false);
      showSnackbar(t('Plots.Snackbar.FetchImageError'), 'error');
    }
  };

  const handleShowComment = (comment) => {
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const renderDataTable = (data, type) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="body2">Type</Typography></TableCell>
            <TableCell><Typography variant="body2">Level</Typography></TableCell>
            <TableCell><Typography variant="body2">Submitted At</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">Image</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">Comment</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">Delete</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            const docId = item.match(/DocId:([\w-]+)/)[1];
            const typeId = item.match(/Type:([\w-]+)/)[1];
            const itemType = getOptionTextById(`question-${type}-type`, typeId);
            const level = item.match(/Level:(\w+)/)[1];
            const hasImageMatch = item.match(/HasImage:(\w+)/);
            const hasImage = hasImageMatch ? hasImageMatch[1] === 'true' : false;
            const timestampMatch = item.match(/Submitted At:Timestamp\(seconds=(\d+), nanoseconds=\d+\)/);
            const timestamp = timestampMatch ? new Date(timestampMatch[1] * 1000) : null;
            const formattedDate = timestamp ? timestamp.toLocaleString() : 'Unknown Date';
            const imageTrackerMatch = item.match(/ImageTracker:\[(.*?)\]/);
            const imageTracker = imageTrackerMatch && imageTrackerMatch[1].trim() ? JSON.parse(`[${imageTrackerMatch[1]}]`) : [true, false, false];
            const commentMatch = item.match(/Comment:(.*)/);
            const comment = commentMatch ? commentMatch[1] : 'No Comment';

            return (
              <TableRow key={index}>
                <TableCell>{itemType}</TableCell>
                <TableCell>{level}</TableCell>
                <TableCell>{formattedDate}</TableCell>
                {/* Image Modal*/}
                <TableCell align="center">
                  {hasImage && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleShowImage(docId, type, hasImage, imageTracker)}
                      disabled={!canUseCamera}
                      size="small"
                      sx={{ fontSize: '0.7rem', mr: 1 }}
                    >
                      Show Images
                    </Button>
                  )}
                </TableCell>
                {/* Comment Modal*/}
                <TableCell align="center">
                  {comment != '' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleShowComment(comment)}
                    size="small"
                    sx={{ fontSize: '0.7rem', mr: 1 }}
                  >
                    Show Comment
                  </Button>
                  )}
                </TableCell>
                {/* Delete Submission Icon*/}
                <TableCell>
                  <IconButton onClick={() => deleteSubmission(docId, type === 'pest')}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Modal open={!!selectedCellData} onClose={() => setSelectedCellData(null)}>
      <Box sx={{ p: 4, bgcolor: 'background.paper', m: 'auto', mt: 5, width: '80%', borderRadius: 3 }}>
        
        {selectedCellData && (
          <Box>
            <Typography variant="h5">{t('Plots.Pests')}</Typography>
            {selectedCellData.rawPestData.length > 0 ? (
              renderDataTable(selectedCellData.rawPestData, 'pest')
            ) : (
              <Typography>No Pest Data</Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h5">{t('Plots.Diseases')}</Typography>
            {selectedCellData.rawDiseaseData.length > 0 ? (
              renderDataTable(selectedCellData.rawDiseaseData, 'disease')
            ) : (
              <Typography>No Disease Data</Typography>
            )}
          </Box>
        )}

        {/* Image Modal */}
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
                maxWidth: '90%',
                maxHeight: '90%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                {imageUrls.length > 0 && (
                <>
                    <img
                    src={imageUrls[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                    style={{ width: 'auto', height: '80vh', maxWidth: '100%' }}
                    />
                    
                    <Box display="flex" justifyContent="center" width="100%" mt={2}>
                    {imageUrls.map((_, index) => (
                        <Button
                        key={index}
                        onClick={() => setCurrentImageIndex(index)}
                        variant="contained"
                        color={currentImageIndex === index ? 'secondary' : 'primary'}
                        sx={{ mx: 0.5 }}
                        >
                        {index + 1}
                        </Button>
                    ))}
                    </Box>
                </>
                )}

                <Button onClick={() => setModalOpen(false)} variant="contained" sx={{ mt: 2 }}>
                Close
                </Button>
            </Box>
        </Modal>

        {/* Comment Modal */}
        <Modal open={commentModalOpen} onClose={() => setCommentModalOpen(false)}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxWidth: '50%',
                minWidth: '300px',
                borderRadius: 1,
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                Comment
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                {selectedComment}
                </Typography>
                <Button onClick={() => setCommentModalOpen(false)} variant="contained">
                Close
                </Button>
            </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default CellModal;
