import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import { auth } from './firebaseConfig';
import { IconButton } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import { useTheme } from '@mui/material/styles';
import * as Sentry from "@sentry/react";

// Import your pages and components
import ContactUs from './pages/ContactUs';
import Account from './pages/Account';
import MyProfile from './pages/MyProfile';
import Home from './pages/Home';
import AccessibilityStatement from './pages/AccessibilityStatement';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondiitons from './pages/TermsAndConditions';
import Login from './pages/Login';
import MyForms from './pages/forms/MyForms';
import MyPlots from './pages/plots/MyPlots';
import EditPlot from './pages/plots/EditPlot';
import CreateFormForPlot from './pages/plots/CreateFormsForPlots';
import EditFormForPlot from './pages/plots/EditFormForPlot';
import CreatePlot from './pages/plots/CreatePlot';
import ExploreMyPlot from './pages/plots/ExploreMyPlot';
import PlotDataEntry from './pages/plots/PlotDataEntry';
import ViewPlotData from './pages/plots/ViewPlotData';
import CreateForm from './pages/forms/CreateForm';
import FormDataEntry from './pages/forms/FormDataEntry';
import ViewData from './pages/forms/ViewData';
import EditForm from './pages/forms/EditForm';
import Products from './pages/Products';
import SignUp from './pages/SignUp';
import NavBar from './components/NavBar';
import MyOrganizations from './pages/MyOrganizations';
import OrganizationMembers from './pages/OrganizationMembers';
import ForgotPassword from './pages/ForgotPassword';
import Support from './pages/Support';
import SupportOrganizations from './pages/support/SupportOrganizations';
import SupportForms from './pages/support/SupportForms';
import SupportPlots from './pages/support/SupportPlots';
import SupportAccount from './pages/support/SupportAccount';
import SupportProducts from './pages/support/SupportProducts';
import SupportGettingStarted from './pages/support/SupportGettingStarted';
import HomePage from './components/HomePage';
import ErrorFallback from './components/ErrorFallback';
import MyNotifications from "./pages/MyNotifications";
import NotFound from './pages/NotFound';

if(import.meta.env.VITE_WEB_MODE === 'PROD'){
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [
//       "localhost:5173", // Local development backend
          /^https:\/\/api\.yourdomain\.com\/.*/, // Production API
        ],
      }),
      Sentry.feedbackIntegration({
        autoInject: false, // Disable auto-injection of the default button
        colorScheme: "system",
        isEmailRequired: true,
        showBranding: false,
        showName: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  });
}


function App() {
  const NavBarHeight = 64;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    // Subscribe to authentication changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user); // Update isLoggedIn state based on user status
    });

    // Cleanup the subscription when the component is unmounted
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const feedback = Sentry.feedbackIntegration({
      autoInject: false, // Disable auto-injection of the default button
      colorScheme: "system",
      isEmailRequired: true,
      showBranding: false,
      showName: false,
    });

    feedback.attachTo(document.querySelector("#custom-feedback-button"));
  }, []);

  return (
    <Router>
      <div>
        {/* Navigation Bar */}
        <NavBar />
        <div className="page-content" style={{ paddingTop: NavBarHeight }}>
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <Routes>
              {/* Redirect based on authentication status */}
              <Route path="/" element={isLoggedIn ? <Navigate to="/account" /> : <Home />} />
              {/* Route definitions */}
              <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndCondiitons />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/support" element={<Support />} />
              <Route path="/support/getting-started" element={<SupportGettingStarted />} />
              <Route path="/support/organizations" element={<SupportOrganizations />} />
              <Route path="/support/plots" element={<SupportPlots />} />
              <Route path="/support/forms" element={<SupportForms />} />
              <Route path="/support/account" element={<SupportAccount />} />
              <Route path="/support/products" element={<SupportProducts />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/account" element={<Account />} />
              <Route path="/my-profile/:userId" element={<MyProfile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/create-plot" element={<CreatePlot />} />
              <Route path="/my-forms/:orgId" element={<MyForms />} />
              <Route path="/my-plots/:orgId" element={<MyPlots />} />
              <Route path="/edit-plot/:orgId/:location/:plotId" element={<EditPlot />} />
              <Route path="/explore-my-plot/:orgId/:location/:plotId" element={<ExploreMyPlot />} />
              <Route path="/plot-data-entry/:orgId/:location/:plotId/:formId" element={<PlotDataEntry />} />
              <Route path="/view-plot-data/:orgId/:location/:plotId/:formId" element={<ViewPlotData />} />
              <Route path="/data-entry/:orgId/:location/:formId" element={<FormDataEntry />} />
              <Route path="/view-data/:orgId/:location/:formId" element={<ViewData />} />
              <Route path="/create-form" element={<CreateForm />} />
              <Route path="/create-forms-for-plots/:orgId/:location/:plotId" element={<CreateFormForPlot />} />
              <Route path="/edit-form/:orgId/:location/:formId" element={<EditForm />} />
              <Route path="/edit-form-for-plot/:orgId/:location/:plotId/:formId" element={<EditFormForPlot />} />
              <Route path="/products" element={<Products />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/my-organizations" element={<MyOrganizations />} />
              <Route path="/organization-members/:orgId" element={<OrganizationMembers />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/my-notifications" element={<MyNotifications />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Sentry.ErrorBoundary>
        </div>
        <IconButton
          id="custom-feedback-button"
          style={{ 
            position: 'fixed', 
            bottom: '2px', 
            right: '2px', 
            zIndex: 100000,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          }}
        >
          <BugReportIcon />
        </IconButton>
      </div>
    </Router>
  );
}

export default App;
