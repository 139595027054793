import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography, Container, Tooltip, Checkbox, FormControlLabel, Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  doc, deleteDoc, collection, getDocs, getDoc, updateDoc, increment, query, where
} from 'firebase/firestore';
import useOrgFirestore from '../../hooks/useOrgFirestore';
import {app, auth} from '../../firebaseConfig';
import Tour from 'reactour';
import OrganizationsDropdown from '../../components/OrgDropdown';
import RoleCheck from '../../utils/roleCheck';
import DeleteDialogBox from '../../components/DeleteDialogBox';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import {deleteObject, getStorage, ref} from "firebase/storage";
import { getFirestoreLocation } from "../../firebaseConfig";
import Link from "@mui/material/Link";
import axios from "axios";
import {useSnackbar} from "../../components/SnackbarContext";
import PermissionsModal from '../../components/PermissionsModal'; // Import PermissionsModal
import SecurityIcon from '@mui/icons-material/Security';

function MyPlots() {
  const [selectedOrg, setSelectedOrg] = useState('');
  const { firestore, location } = useOrgFirestore(selectedOrg);
  const navigate = useNavigate();
  const [plots, setPlots] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('myPlotsTourSeen') === 'true');
  const [userRole, setUserRole] = useState('');
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0 });
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: null, name: null });
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [managePermissionsDialogOpen, setManagePermissionsDialogOpen] = useState(false);
  const [selectedItemForPermissions, setSelectedItemForPermissions] = useState(null); // renamed for flexibility
  const [orgUserData, setOrgUserData] = useState({});
  const [permissionManagement, setPermissionManagement] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsTourOpen(!tourSeen);
    }
  }, [loading, tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('myPlotsTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const handleManagePermissionsClick = (itemId, itemName, userPermissions) => {
    setSelectedItemForPermissions({
      id: itemId,
      name: itemName,
      userPermissions,
      type: 'plots', // you can dynamically set this to 'forms' if needed
    });
    setManagePermissionsDialogOpen(true);
  };

  const handlePermissionsUpdate = (itemId, updatedPermissions) => {
    setPlots((prevPlots) =>
        prevPlots.map((plot) =>
            plot.plotId === itemId ? { ...plot, userPermissions: updatedPermissions } : plot
        )
    );
  };


  const steps = [
    {
      selector: '[data-tour="createPlot"]',
      content: t('Plots.ReactTour.Desc.0'),
    },
    {
      selector: '',
      content: () => (
          <FormControlLabel
              control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
              label={t("General.DoNotShow")}
          />
      ),
    },
  ];

  useEffect(() => {
    const fetchOrgData = async () => {
      if (selectedOrg && firestore) {
        const orgDocRef = doc(firestore, `organizations/${selectedOrg}`);
        const orgDoc = await getDoc(orgDocRef);
        if (orgDoc.exists()) {
          const { currentTotalData, maximumTotalData } = orgDoc.data();
          setOrgDataLimits({ currentTotalData, maximumTotalData });


          // Fetch user data within the organization
          const usersCollectionRef = collection(firestore, `organizations/${selectedOrg}/users`);
          const usersSnapshot = await getDocs(usersCollectionRef);

          const usersData = {};
          usersSnapshot.forEach((userDoc) => {
            const { role, email } = userDoc.data();
            usersData[userDoc.id] = { role, email };
          });

          setOrgUserData(usersData); // Set orgUserData with the fetched users data
        }
      }
    };

    fetchOrgData().catch((error) => {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      setPlots([]);
    });
  }, [selectedOrg, firestore]);

  useEffect(() => {
    if (selectedOrg && firestore && currentAuthUid) {
      const fetchPlots = async () => {
        try {
          const orgDocRef = doc(firestore, `organizations/${selectedOrg}`);
          const orgDoc = await getDoc(orgDocRef);
          const {  permissionManagement = false } = orgDoc.data();

          // Set permissionManagement to its value from Firestore, or to false if it doesn't exist
          setPermissionManagement(permissionManagement);


          const plotsRef = collection(firestore, `organizations/${selectedOrg}/plots`);
          const plotsQuery = query(plotsRef, where('deleted', '==', false));
          const querySnapshot = await getDocs(plotsQuery);

          let fetchedPlots = querySnapshot.docs.map(doc => ({
            plotId: doc.id,
            plotName: doc.data().plotName,
            userPermissions: doc.data().userPermissions,
          }));

          // Only filter by permissions if permissionManagement is true
          if (permissionManagement) {
            fetchedPlots = fetchedPlots.filter(plot => plot.userPermissions?.[currentAuthUid] !== false);
          }
          setPlots(fetchedPlots);
        } catch (error) {
          showSnackbar('Error fetching plots, try again', 'error');
          Sentry.captureException(error);
          setPlots([]);
        }
      };

      fetchPlots();
    } else {
      setPlots([]);
    }
  }, [selectedOrg, firestore, currentAuthUid, permissionManagement]);



  const handleExplorePlotClick = (plotId) => {
    navigate(`/explore-my-plot/${selectedOrg}/${location}/${plotId}`);
  };

  const handleEditPlotClick = (plotId) => {
    navigate(`/edit-plot/${selectedOrg}/${location}/${plotId}`);
  };

  const handleAddPlotClick = () => {
    navigate('/create-plot/');
  };

  const handleOrgChange = (org) => {
    setSelectedOrg(org);
  };

  const handleDeletePlotClick = (plotId, plotName) => {
    setSelectedItem({ id: plotId, name: plotName });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem({ id: null, name: null });
  };

  const deleteImage = async (imagePath, storage) => {
    try {
      const imageRef = ref(storage, imagePath);
      await deleteObject(imageRef);
    } catch (error) {
      showSnackbar('Error deleting plot', 'error');
      Sentry.captureException(error);
    }
  };

  const handleDeletePlot = async () => {
    try {
      const {f, storageBucket} = getFirestoreLocation(location);
      const storage = getStorage(app, storageBucket);
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      handleDialogClose();
    }

    if (selectedItem.id) {
      try {
        const plotId = selectedItem.id;
        let totalSubmissionsToDelete = 0;

        const plotRef = doc(firestore, `organizations/${selectedOrg}/plots`, plotId);
        updateDoc(plotRef, { deleted: true });

        const formCollection = collection(plotRef, 'formsForPlots');
        const formDocs = await getDocs(formCollection);

        for (const plotFormRef of formDocs.docs) {
          const formData = plotFormRef.data();

          if (formData.numberOfSubmissions) {
            totalSubmissionsToDelete += formData.numberOfSubmissions;
          }

          updateDoc(plotFormRef.ref, {deleted: true});
        }

        const orgDocRef = doc(firestore, `organizations/${selectedOrg}`);
        updateDoc(orgDocRef, {
          currentTotalData: increment(-totalSubmissionsToDelete),
        });

        const imgData = {
          mode: import.meta.env.VITE_WEB_MODE,
          location: location,
          path: `${selectedOrg}/${plotId}`
        };

        const docData = {
          mode: import.meta.env.VITE_WEB_MODE,
          location: location,
          path: `organizations/${selectedOrg}/plots/${plotId}`
        };

        await axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_DOCUMENTS, docData);
        await axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_IMAGES, imgData);

        setPlots(prevPlots => prevPlots.filter(plot => plot.plotId !== plotId));
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      } finally {
        handleDialogClose();
      }
    }
  };

  const isPlotAdditionAllowed = orgDataLimits.currentTotalData < orgDataLimits.maximumTotalData;

  return (
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <RoleCheck
            currentAuthUid={currentAuthUid}
            orgId={selectedOrg}
            rolesAllowed={['Admin', 'Owner', 'Contributor', 'Viewer']}
            redirectPage='/account'
            firestore={firestore}
            setUserRole={setUserRole}
        />
        <Typography variant="h3" gutterBottom>
          {t('Navbar.MyPlots')}
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
        <Typography variant="h6" gutterBottom>
          {t('Plots.Desc')}
        </Typography>
        <OrganizationsDropdown selectedOrg={selectedOrg} setSelectedOrg={handleOrgChange} setLoading={setLoading} />
        <Tooltip title={isPlotAdditionAllowed ? '' : 'Data limit exceeded'}>
        <span>
          <Button
              data-tour="createPlot"
              variant="contained"
              onClick={handleAddPlotClick}
              sx={{ my: 2 }}
              disabled={!isPlotAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner')}
          >
            {t('Plots.Add')}
          </Button>
        </span>
        </Tooltip>
        {plots.length === 0 ? (
            selectedOrg ? (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {t('Plots.Empty')}
                  <Link
                      href="/support/plots"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    {t('General.ClickHere')}
                  </Link>{' '}
                  {t('General.LearnToGetStarted')}
                </Typography>
            ) : (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {t('General.NoOrg')}
                  {' '}<Link
                    href="/support/products"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'blue', textDecoration: 'underline' }}
                >
                  {t('General.ClickHere')}
                </Link>{' '}
                  {t('General.LearnAboutOrg')}
                </Typography>
            )
        ) : (
            <Grid container spacing={3}>
              {plots.map((plot) => (
                  <Grid item xs={12} sm={12} md={12} key={plot.plotId}>
                    <Card elevation={5}>
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={11}>
                            <Grid container direction="column" alignItems="center">
                              <Typography variant="h5" align="center" sx={{ fontSize: '1.4rem' }}>
                                {plot.plotName}
                              </Typography>
                              <Grid container justifyContent="center" sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleExplorePlotClick(plot.plotId)}
                                    sx={{ mt: 1, color: 'primary.main', backgroundColor: 'secondary.main' }}
                                >
                                  {t('Plots.Explore')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                              item
                              xs={1}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                pr: 1,
                              }}
                          >
                            {/* Edit Button */}
                            <Tooltip title={t('Plots.Edit')}>
                  <span>
                    <Button
                        onClick={() => handleEditPlotClick(plot.plotId)}
                        disabled={!isPlotAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner')}
                    >
                      <EditIcon />
                    </Button>
                  </span>
                            </Tooltip>

                            {/* Delete Button */}
                            <Tooltip title={t('Plots.Delete')}>
                  <span>
                    <Button
                        onClick={() => handleDeletePlotClick(plot.plotId, plot.plotName)}
                        disabled={!(userRole === 'Admin' || userRole === 'Owner')}
                    >
                      <DeleteIcon />
                    </Button>
                  </span>
                            </Tooltip>

                            {permissionManagement && (userRole === 'Admin' || userRole === 'Owner') && (
                                <Tooltip title={t('Plots.ManagePermissions')}>
                                    <span>
                                      <Button
                                          onClick={() => handleManagePermissionsClick(plot.plotId, plot.plotName, plot.userPermissions)}
                                          disabled={!(userRole === 'Admin' || userRole === 'Owner')}
                                      >
                                        <SecurityIcon />
                                      </Button>
                                    </span>
                                </Tooltip>
                            )}


                            {/* Delete Dialog Box */}
                            <DeleteDialogBox
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                onConfirm={handleDeletePlot}
                                itemName={selectedItem.name}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
              ))}
            </Grid>
        )}
        <PermissionsModal
            item={selectedItemForPermissions}
            isOpen={managePermissionsDialogOpen}
            onClose={() => setManagePermissionsDialogOpen(false)}
            isAdminOrOwner={userRole === 'Admin' || userRole === 'Owner'}
            userData={orgUserData}
            firestore={firestore}
            orgId={selectedOrg}
            onPermissionsUpdate={handlePermissionsUpdate}
        />

        <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
        />
      </Container>
  );
}

export default MyPlots;
