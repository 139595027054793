import React, { useState } from 'react';
import {
  TextField, IconButton, Select, Paper, MenuItem, FormControl, InputLabel,
  Checkbox, FormControlLabel, Button, Box, List, ListItem, ListItemText,
  ListItemSecondaryAction, Typography, Input,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import restrictedCharsData from '../local_data/restricted-chars.json';
import { useTranslation } from 'react-i18next';

export function Question({
  index, updateQuestion, removeQuestion, moveQuestionUp, moveQuestionDown, data, dataTypes,
}) {
  const [text, setText] = useState(data.text || '');
  const [dataType, setDataType] = useState(data.dataType || 'text');
  const [mandatory, setMandatory] = useState(data.mandatory || false);
  const [options, setOptions] = useState(data.options || []);
  const [newOption, setNewOption] = useState('');
  const [editingOptionIndex, setEditingOptionIndex] = useState(null);
  const [editedOptionValue, setEditedOptionValue] = useState('');
  const [error, setError] = useState('');
  const [optionError, setOptionError] = useState('');
  const isEditable = data.editable !== false;
  const { t } = useTranslation();

  const restrictedChars = new RegExp(`[${restrictedCharsData.restricted_chars.join('')}]`);

  const validateText = (text) => {
    return !restrictedChars.test(text);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value.slice(0, 63);
    if (validateText(newText)) {
      setText(newText);
      updateQuestion(index, { ...data, text: newText });
      setError('');
    } else {
      setError(t('Snackbar.RestrictredCharsMessage'));
    }
  };

  const handleDataTypeChange = (e) => {
    const newDataType = e.target.value;
    setDataType(newDataType);
    updateQuestion(index, { ...data, dataType: newDataType, mandatory: newDataType === 'image' ? false : mandatory, options: [] }); // Reset options when type changes and disable mandatory if image
    if (newDataType === 'image') {
      setMandatory(false); // Ensure mandatory stays unchecked for image type
    }
  };

  const handleMandatoryChange = (e) => {
    // Only allow changing mandatory if the dataType is not 'image'
    if (dataType !== 'image') {
      setMandatory(e.target.checked);
      updateQuestion(index, { ...data, mandatory: e.target.checked });
    }
  };

  const handleAddOption = () => {
    const trimmedOption = newOption.trim().slice(0, 63);
    if (trimmedOption && validateText(trimmedOption)) {
      if (options.find(option => option.value.toLowerCase() === trimmedOption.toLowerCase())) {
        setOptionError('Option already exists.');
        return;
      }
      const updatedOptions = [...options, { id: Date.now().toString(), value: trimmedOption }];
      setOptions(updatedOptions);
      setNewOption('');
      updateQuestion(index, { ...data, options: updatedOptions });
      setOptionError('');
    } else {
      setOptionError(t('Snackbar.RestrictredCharsMessage'));
    }
  };

  const handleRemoveOption = (optionIndex) => {
    const updatedOptions = options.filter((_, idx) => idx !== optionIndex);
    setOptions(updatedOptions);
    updateQuestion(index, { ...data, options: updatedOptions });
  };

  const handleOptionEditChange = (e) => {
    const newValue = e.target.value.slice(0, 63);
    if (validateText(newValue)) {
      setEditedOptionValue(newValue);
      setOptionError('');
    } else {
      setOptionError(t('Snackbar.RestrictredCharsMessage'));
    }
  };

  const startEditingOption = (optionIndex) => {
    setEditingOptionIndex(optionIndex);
    setEditedOptionValue(options[optionIndex].value);
  };

  const saveEditedOption = () => {
    if (validateText(editedOptionValue)) {
      const updatedOptions = [...options];
      updatedOptions[editingOptionIndex].value = editedOptionValue;
      setOptions(updatedOptions);
      updateQuestion(index, { ...data, options: updatedOptions });
      setEditingOptionIndex(null);
      setEditedOptionValue('');
      setOptionError('');
    } else {
      setOptionError(t('Snackbar.RestrictredCharsMessage'));
    }
  };

  const moveOptionUp = (optionIndex) => {
    if (optionIndex === 0) return;
    const updatedOptions = [...options];
    [updatedOptions[optionIndex - 1], updatedOptions[optionIndex]] = [updatedOptions[optionIndex], updatedOptions[optionIndex - 1]];
    setOptions(updatedOptions);
    updateQuestion(index, { ...data, options: updatedOptions });
  };

  const moveOptionDown = (optionIndex) => {
    if (optionIndex === options.length - 1) return;
    const updatedOptions = [...options];
    [updatedOptions[optionIndex + 1], updatedOptions[optionIndex]] = [updatedOptions[optionIndex], updatedOptions[optionIndex + 1]];
    setOptions(updatedOptions);
    updateQuestion(index, { ...data, options: updatedOptions });
  };

  return (
      <Paper variant="outlined" sx={{ p: 2, mb: 2, position: 'relative', display: 'flex', alignItems: 'center' }}>
        <IconButton
            onClick={() => removeQuestion(index)}
            aria-label="Remove question"
            sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <DeleteIcon />
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
          <IconButton onClick={moveQuestionUp} aria-label="Move question up" size="large" sx={{ fontSize: '2rem' }}>
            <ArrowUpwardIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
          <IconButton onClick={moveQuestionDown} aria-label="Move question down" size="large" sx={{ fontSize: '2rem' }}>
            <ArrowDownwardIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
              fullWidth
              data-testid="question-name"
              variant="outlined"
              placeholder="Enter your question"
              value={text}
              onChange={handleTextChange}
              disabled={!isEditable}
              inputProps={{ maxLength: 63 }}
              sx={{ width: '90%', display: 'block', mr: 'auto' }}
              error={!!error}
              helperText={error}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <FormControl fullWidth margin="normal" sx={{ width: '70%', maxWidth: 220 }}>
              <InputLabel>{t('Forms.DataType')}</InputLabel>
              <Select
                  data-testid="data-type-select"
                  value={dataType}
                  onChange={handleDataTypeChange}
                  label="Data Type"
                  sx={{ display: 'block', mr: 2 }}
                  disabled={!isEditable}
              >
                {dataTypes.map((type) => (
                    <MenuItem key={type} value={type.toLowerCase()}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
                control={<Checkbox checked={mandatory} onChange={handleMandatoryChange} />}
                label="Mandatory"
                sx={{ ml: 2 }}
                disabled={dataType === 'image'}
            />
          </Box>
          {(dataType === 'dropdown' || dataType === 'multiple choice') && (
              <FormControl fullWidth margin="normal">
                <Typography variant="h6">{t('Forms.Choice')}</Typography>
                <List dense>
                  {options.map((option, idx) => (
                      <ListItem key={option.id} sx={{ pl: 1, border: '1px solid #ccc', borderRadius: '4px', mb: 1 }}>
                        {editingOptionIndex === idx ? (
                            <Input
                                value={editedOptionValue}
                                onChange={handleOptionEditChange}
                                onBlur={saveEditedOption}
                                autoFocus
                                inputProps={{ maxLength: 63 }}
                                error={!!optionError}
                            />
                        ) : (
                            <ListItemText
                                primary={option.value}
                                onClick={() => startEditingOption(idx)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="move up" onClick={() => moveOptionUp(idx)}>
                            <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="move down" onClick={() => moveOptionDown(idx)}>
                            <ArrowDownwardIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveOption(idx)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                  ))}
                </List>
                {optionError && (
                    <Typography color="error" variant="body2">
                      {optionError}
                    </Typography>
                )}
                <Box display="flex" mt={2}>
                  <TextField
                      fullWidth
                      variant="outlined"
                      value={newOption}
                      onChange={(e) => {
                        const newValue = e.target.value.slice(0, 63);
                        if (validateText(newValue)) {
                          setNewOption(newValue);
                          setOptionError('');
                        } else {
                          setOptionError(t('Snackbar.RestrictredCharsMessage'));
                        }
                      }}
                      placeholder="Add a new choice"
                      inputProps={{ maxLength: 63 }}
                      sx={{ mr: 1 }}
                      error={!!optionError}
                  />
                  <Button variant="contained" onClick={handleAddOption} disabled={!newOption.trim()}>
                    {t('Forms.AddButton')}
                  </Button>
                </Box>
              </FormControl>
          )}
          {dataType === 'image' && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                User can upload image
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
  );
}
