import React, { useState } from 'react';
import {
  TextField,
  Button, Box, Paper, IconButton, Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSnackbar } from '../SnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import restrictedChars from '../../local_data/restricted-chars.json'; // Import restricted characters
import * as Sentry from '@sentry/react'

export function PlotQuestion({
  id, index, updateQuestion, data = {}, editableText = true, isEditing = false,
}) {
  const [text, setText] = useState(data.text || '');
  const [dataType, setDataType] = useState(data.dataType || 'Text');
  const [dropdownOptions, setDropdownOptions] = useState(data.options || []);
  const { showSnackbar } = useSnackbar();

  const validateQuestionText = (text) => {
    try {
      const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
      return !restrictedCharsRegex.test(text);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (validateQuestionText(newText)) {
      setText(newText);
      updateQuestion(index, { ...data, text: newText });
    } else {
      showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
    }
  };

  const handleDropdownOptionsChange = (newOptions) => {
    setDropdownOptions(newOptions);
    updateQuestion(index, { ...data, options: newOptions });
  };

  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
      }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Question"
          placeholder="Enter your question"
          value={text}
          onChange={handleTextChange}
          disabled={!editableText}
        />

        {dataType === 'dropdown' && (
          <DropdownOptions
            options={dropdownOptions}
            setOptions={handleDropdownOptionsChange}
            editable={data.editableOptions}
            isEditing={isEditing}  // Added prop
          />
        )}

      </Box>
    </Paper>
  );
}

export function DropdownOptions({ options, setOptions, editable, isEditing }) {
  const [newOption, setNewOption] = useState('');
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const validateOptionText = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const handleAddOption = () => {
    if (newOption.trim() && newOption.length <= 63) {
      if (validateOptionText(newOption.trim())) {
        const updatedOptions = [...options, { text: newOption.trim(), id: uuidv4(), isDeleted: false }];
        setOptions(updatedOptions);
        setNewOption('');
      } else {
        showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
      }
    } else if (newOption.length > 63) {
      showSnackbar('Option length should not exceed 63 characters.', 'error');
    }
  };

  const handleRemoveOption = (idx) => {
    if (isEditing) {
      const updatedOptions = options.map((option, index) => index === idx ? { ...option, isDeleted: true } : option);
      setOptions(updatedOptions);
    } else {
      const updatedOptions = options.filter((_, index) => index !== idx);
      setOptions(updatedOptions);
    }
  };

  const handleMoveUp = (idx) => {
    if (idx > 0) {
      const updatedOptions = [...options];
      [updatedOptions[idx - 1], updatedOptions[idx]] = [updatedOptions[idx], updatedOptions[idx - 1]];
      setOptions(updatedOptions);
    }
  };

  const handleMoveDown = (idx) => {
    if (idx < options.length - 1) {
      const updatedOptions = [...options];
      [updatedOptions[idx + 1], updatedOptions[idx]] = [updatedOptions[idx], updatedOptions[idx + 1]];
      setOptions(updatedOptions);
    }
  };

  const handleOptionChange = (idx, value) => {
    if (validateOptionText(value) && value.length <= 63) {
      const updatedOptions = options.map((option, index) => (index === idx ? { ...option, text: value } : option));
      setOptions(updatedOptions);
    } else {
      showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
    }
  };

  return (
    <div className="dropdownOptions">
      {options.map((option, idx) => (
        !option.isDeleted && (
          <Grid container spacing={1} alignItems="center" key={option.id}>
            {editable && (
              <Grid item>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IconButton onClick={() => handleMoveUp(idx)} disabled={idx === 0} size="small">
                    <ArrowUpwardIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => handleMoveDown(idx)} disabled={idx === options.length - 1} size="small">
                    <ArrowDownwardIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            )}
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                value={option.text}
                onChange={(e) => handleOptionChange(idx, e.target.value)}
                inputProps={{ maxLength: 63 }}
              />
            </Grid>
            {editable && (
              <Grid item>
                <IconButton onClick={() => handleRemoveOption(idx)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )
      ))}
      {editable && (
        <>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter dropdown option"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            inputProps={{ maxLength: 63 }}
          />
          <Button onClick={handleAddOption}>{t('Plots.AddOption')}</Button>
        </>
      )}
    </div>
  );
}
