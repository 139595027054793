import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const RoleCheck = ({ currentAuthUid, orgId, rolesAllowed, redirectPage, firestore, setUserRole }) => {
  const navigate = useNavigate();
  const [roleChecked, setRoleChecked] = useState(false);

  useEffect(() => {

    const checkUserRole = async () => {

      if (!currentAuthUid || !orgId || !firestore) {
        console.log('Required parameters are missing, not checking user role yet.');
        return;
      }

      const userDocRef = doc(firestore, `organizations/${orgId}/users/${currentAuthUid}`);

      try {
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);  // Pass the role back to the parent component

          if (!rolesAllowed.includes(userData.role)) {
            navigate(redirectPage);
          } else {
            setRoleChecked(true);
          }
        } else {
          navigate(redirectPage);
        }
      } catch (error) {
        navigate(redirectPage);
      }
    };

    if (currentAuthUid && orgId && firestore) {
      checkUserRole();
    }
  }, [currentAuthUid, orgId, firestore, navigate, redirectPage, rolesAllowed, roleChecked, setUserRole]);

  return null;
};

export default RoleCheck;
