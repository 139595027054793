import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Grid, Box, Divider, CircularProgress } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import { onAuthStateChanged } from "firebase/auth";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SubscriptionTiers from '../components/SubscriptionTiers';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import AlertDialog from '../components/ConfirmDialogBox';
import CountryDropdown from '../components/CountryDropdown';
import { useSnackbar } from '../components/SnackbarContext';
import { sendNotification } from '../utils/SendNotification';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

function Products() {
  const [userEmail, setUserEmail] = useState(null);
  const [userHasOrganization, setUserHasOrganization] = useState(false);
  const [userInCheckout, setUserInCheckout] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('default');
  const [inClientCheckout, setInClientCheckout] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [orgName, setOrgName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [currentPlan, setCurrentPlan] = useState('');
  const [open, setOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [priceId, setPriceId] = useState('');
  const [dialogMode, setDialogMode] = useState('');
  const [dialog, setDialog] = useState('');
  const [title, setTitle] = useState('');
  const [tickLabel, setTickLabel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('CA');
  const [locationError, setLocationError] = useState(false);
  const [orgNameError, setOrgNameError] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {firestore, storageBucket} = getFirestoreLocation('default');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchUserData(user.uid);
      } else {
        setUserId('');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const ownsOrganization = userData['ownsOrganization'];
        const inCheckout = userData['inCheckout'];
        const userEmail = userData['email'];
        const plan = userData['currentPlan'];
        const subId = userData['subscriptionId'];
        const orgId = userData['ownedOrgId'];
        setUserHasOrganization(ownsOrganization);
        setUserInCheckout(inCheckout);
        setUserEmail(userEmail);
        setSubscriptionId(subId);
        setOrgId(orgId);
        if (ownsOrganization) {
          setCurrentPlan(plan);
        } else {
          setCurrentPlan('None');
        }
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      const loadPaddleScript = () => {
        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
        script.async = true;
        script.onload = () => initializePaddle();
        document.body.appendChild(script);
      };
      const initializePaddle = () => {
        if (import.meta.env.VITE_WEB_MODE === 'TEST') {
          window.Paddle.Environment.set('sandbox');
        } else if (import.meta.env.VITE_WEB_MODE === 'LIVE') {
          window.Paddle.Environment.set('live');
        }
        window.Paddle.Initialize({
          token: import.meta.env.VITE_PADDLE_CLIENT_TOKEN,
          eventCallback: checkoutEventHandler
        });
      };

      loadPaddleScript();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useEffect(() => {
    try {
      const asyncFunction = async () => {
        const userDocRef = doc(firestore, 'users', userId);

        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const ownsOrganization = userData['ownsOrganization'];
          const inCheckout = userData['inCheckout'];
          setUserHasOrganization(ownsOrganization);
          setUserInCheckout(inCheckout);
          await updateDoc(userDocRef, {
            inCheckout: new Date()
          });
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
        }
      };

      let interval;
      if (inClientCheckout) {
        interval = setInterval(() => {
          asyncFunction();
        }, 5000);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  });

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const checkoutEventHandler = async (data) => {
    try {

      /*
      const isFree = data.data.items[0].product.name === 'Free Organization';

      if (data.name === 'checkout.customer.created' && isFree){
        console.log(data);
        const transactionId = data.data.transaction_id;
        const customerId = data.data.customer.id;
        const addressId = data.data.customer.address.id;
        window.Paddle.Checkout.close();
        const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_CREATE_TRANSACTION, {
          customerId: customerId,
          addressId: addressId,
          environment: import.meta.env.VITE_WEB_MODE
        });
        console.log(response);
      }
      */

      if (data.name === 'checkout.closed') {
        setInClientCheckout(false);
        const userDocRef = doc(firestore, `users/${auth.currentUser.uid}`);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          setUserInCheckout(false);
          await updateDoc(userDocRef, {
            inCheckout: false
          });
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const itemsList = {
    Free: {
      priceId: import.meta.env.VITE_PADDLE_PRICE_ID_FREE,
      quantity: 1,
    },
    Basic: {
      priceId: import.meta.env.VITE_PADDLE_PRICE_ID_BASIC,
      quantity: 1,
    },
    Advanced: {
      priceId: import.meta.env.VITE_PADDLE_PRICE_ID_ADVANCED,
      quantity: 1,
    },
    Premium: {
      priceId: import.meta.env.VITE_PADDLE_PRICE_ID_PREMIUM,
      quantity: 1,
    },
  };

  const openCheckout = async (item) => {
    try {
      if (!auth.currentUser) {
        navigate('/login');
        return;
      }

      const userDocRef = doc(firestore, 'users', userId);

      const userDocSnapshot = await getDoc(userDocRef);
      let inCheckout;
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const ownsOrganization = userData['ownsOrganization'];
        inCheckout = userData['inCheckout'];
        setUserHasOrganization(ownsOrganization);
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return;
      }

      if (inCheckout) {
        if (new Date() - inCheckout.toDate() < 10000) {
          return;
        }
        showSnackbar(t('Snackbar.TryAgain'), 'error');
      }

      setUserInCheckout(inCheckout);

      if (userHasOrganization) {
        showSnackbar(t('Products.Snackbar.AlreadyOwnsOrgError'), 'error');
        return;
      }

      if (!selectedLocation) {
        showSnackbar(t('Products.Snackbar.SelectDatabaseLocation'), 'error');
        setLocationError(true);
        return;
      } else {
        setLocationError(false);
      }

      if (!orgName) {
        showSnackbar(t('Products.Snackbar.OrgNameError'), 'error');
        setOrgNameError(true);
        return;
      } else {
        if(orgName.length > 64){
          showSnackbar(t('Products.Snackbar.OrgNameMax'), 'error');
          setOrgNameError(true);
          return;
        }
        setOrgNameError(false);
      }

      const customerInfo = {
        id: userId,
      };

      const customData = {
        userId: userId,
        selectedLocation: selectedLocation,
        orgName: orgName,
      };


      setInClientCheckout(true);
      window.Paddle.Checkout.open({
        items: [item],
        customer: customerInfo,
        customData: customData,
        successCallback: function(data) {
          console.log('Checkout successful:', data);

          // Refresh the page after 10 seconds
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        },
        closeCallback: function() {
          setInClientCheckout(false);
          showSnackbar(t('Products.Snackbar.CheckoutCancelled'), 'error');
        }
      });




    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const createButtonHandler = (tierTitle) => async () => {
    const userDocRef = doc(firestore, 'users', userId);

    const userDocSnapshot = await getDoc(userDocRef);
    let subscriptionId;
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const ownsOrganization = userData['ownsOrganization'];
      subscriptionId = userData['subscriptionId'];
      setUserHasOrganization(ownsOrganization);
    } else {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
    }

    const item = itemsList[tierTitle];
    if (userHasOrganization) {
      setSubscriptionId(subscriptionId);
      setPriceId(item.priceId);
      setDialogMode('update');
      setTitle(`Update to ${tierTitle}`);
      setDialog(`Are you sure you wish to change your plan from ${currentPlan} to ${tierTitle}`);
      setTickLabel(`Yes, I am sure`);
      setOpen(true);
    } else {
      openCheckout(item);
    }
  };

  const buttonHandlers = [
    createButtonHandler('Free'),
    createButtonHandler('Basic'),
    createButtonHandler('Advanced'),
    createButtonHandler('Premium'),
    createButtonHandler('Enterprise'),
  ];

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleOrgNameChange = (event) => {
    setOrgName(event.target.value);
  };

  const changePlan = async (subscriptionId, priceId) => {
    try {
      const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_UPDATE_SUBSCRIPTION, {
        subscriptionId: subscriptionId,
        priceId: priceId,
        environment: import.meta.env.VITE_WEB_MODE
      });

    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  const deletePlan = async () => {
    try {
      const userDocRef = doc(firestore, 'users', userId);

      const userDoc = await getDoc(userDocRef);

      const userData = userDoc.data();
      const subscriptionId = userData.subscriptionId;

      if (!subscriptionId) {
        return;
      }

      const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_ORGANIZATION, {
        orgId: orgId,
        mode: import.meta.env.VITE_WEB_MODE
      });

    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const onDeleteClick = async () => {
    setDialogMode('delete');
    setTitle(`Delete Plan`);
    setDialog(`Are you sure you wish to delete your ${currentPlan} plan? This cannot be undone`);
    setTickLabel(`Yes, I am sure`);
    setOpen(true);
  };

  const handleAgree = async () => {
    setIsLoading(true);
    if (dialogMode === 'update') {
      await changePlan(subscriptionId, priceId);
    } else if (dialogMode === 'delete') {
      await deletePlan();
    }

    setTimeout(() => {
      setOpen(false);
      setDialogMode('');
      setIsLoading(false);
      navigate('/account');
    }, 5000);
  };

  const UpdatePaymentMethodButton = () => {
    const handleUpdatePaymentMethod = async () => {
      try {
        const response = await fetch(import.meta.env.VITE_FUNCTION_LINK_UPDATE_PAYMENT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId,
            mode: import.meta.env.VITE_WEB_MODE
          }),
        });

        console.log(123);

        const data = await response.json();

        if (data.success) {
          window.Paddle.Checkout.open({
            transactionId: data.data.data.id
          });
        } else {
          showSnackbar(t('Products.Snackbar.PaymentUpdateError'), 'error');
        }
      } catch (error) {
        showSnackbar(t('Products.Snackbar.PaymentUpdateError'), 'error');
        Sentry.captureException(error);
      }
    };
    handleUpdatePaymentMethod();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 3 }}>
      <Typography variant="h3" gutterBottom>
        {t('Navbar.Products')}
      </Typography>
      <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6}>
            {!userHasOrganization && (
              <Typography variant="h6" gutterBottom style={{ flexGrow: 1 }}>
                {t('Products.SelectCountryDesc')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {!userHasOrganization && (
              <Box width="100%">
                <CountryDropdown onCountrySelect={handleCountrySelect} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 5 }} />
      {userId && !userHasOrganization && (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">
                {t('Products.SelectDatabaseLocDesc1')}<br />
                {t('Products.SelectDatabaseLocDesc2')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="location-select-label">Select Location</InputLabel>
                <Select
                  error={locationError}
                  onFocus={() => setLocationError(false)}
                  labelId="location-select-label"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  label="Select Your Location"
                >
                  <MenuItem value="australia_sydney">Australia</MenuItem>
                  <MenuItem value="south_america_east_brazil_sao_paulo">Brazil</MenuItem>
                  <MenuItem value="asia_east_taiwan">East Asia</MenuItem>
                  <MenuItem value="europe">Europe</MenuItem>
                  <MenuItem value="asia_south_delhi_india">India</MenuItem>
                  <MenuItem value="middle_east_israel_tel_aviv">Middle East</MenuItem>
                  <MenuItem value="northamerica">North America</MenuItem>
                  <MenuItem value="africa_south_johannesburg">South Africa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">
                {t('Products.OrgNameDesc1')}<br />
                {t('Products.OrgNameDesc2')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={orgNameError}
                onFocus={() => setOrgNameError(false)}
                variant="outlined"
                fullWidth
                margin="normal"
                label="Organization Name"
                value={orgName}
                onChange={handleOrgNameChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <SubscriptionTiers
            buttonHandlers={buttonHandlers}
            currentPlan={currentPlan}
            countryCode={selectedCountry}
            loggedIn={userId}
        />
      </Grid>

      {userId && userHasOrganization && (
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 0, paddingBottom: 2}}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => UpdatePaymentMethodButton()} // Directly call UpdatePaymentMethodButton
            >
              {t('Products.ChangePaymentInfo')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDeleteClick()} // Directly call deletePlan
            >
              {t('Products.DeleteSubscription')}
            </Button>
          </Grid>
        </Grid>
      )}



      <AlertDialog open={open} onClose={handleClose} onAgree={handleAgree} dialogue={dialog} title={title} tickBoxContent={tickLabel} isLoading={isLoading} />
    </Container>
  );
}

export default Products;
