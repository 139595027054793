import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Card, CardContent, Box } from '@mui/material';
import {doc, onSnapshot, deleteDoc, getDoc, getDocs, collection} from 'firebase/firestore';
import { sendPasswordResetEmail, deleteUser } from 'firebase/auth';
import { useSnackbar } from '../components/SnackbarContext';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import DeleteDialogBox from '../components/DeleteDialogBox';
import axios from "axios";
import useOrgFirestore from '../hooks/useOrgFirestore';
import { useNavigate } from 'react-router-dom';

function MyProfile() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { firestore, storageBucket } = getFirestoreLocation('(default)');
  const { t } = useTranslation();
  const [orgID, setOrgID] = useState('');
  const [ownsOrg, setOwnsOrg] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = auth.currentUser;
    const currentUserUID = currentUser ? currentUser.uid : null;

    if (currentUserUID) {
      const userRef = doc(firestore, `users/${currentUserUID}`);

      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setUsername(data.email);
          setEmail(data.email); // Set the email state for password reset
          setOwnsOrg(data.ownsOrganization);
          setOrgID(data.ownedOrgId);
          setSubscriptionID(data.subscriptionId);
        } else {
          console.log('No data available');
        }
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [firestore]);

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      showSnackbar(t('MyProfile.ChangePasswordMessage'), 'success');
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  const handleDeleteAccount = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      return;
    }

    setOpen(false); // Close the delete dialog box
    try {
      const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_ACCOUNT, {
        userId: currentUser.uid,
        mode: import.meta.env.VITE_WEB_MODE,
      });

      if (response.data.success) {
        // Successfully deleted account
        showSnackbar(t('MyProfile.DeleteAccountMessage'), 'success');

        // Log the user out and navigate to the homepage
        navigate('/');
      } else {
        // Handle unsuccessful deletion
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        console.error(response.data.error);
      }
    } catch (error) {
      // Log and show error
      Sentry.captureException(error);
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      console.error('Error deleting account:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4, p: 4, borderRadius: 2, boxShadow: 3 }}>
        <Card>
          <CardContent>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t('MyProfile.Title')}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', mb: 1 }}>
                {t('Auth.EmailLabel')}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', color: 'text.secondary' }}>
                {username || '...'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', mb: 1 }}>
                {t('Auth.PasswordLabel')}
              </Typography>
              <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2, mt: 1 }}
                  onClick={handleForgotPassword}
              >
                {t('MyProfile.ChangePasswordButton')}
              </Button>
            </Box>
            <Box sx={{ ml: 2, mt: 3 }}>
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
              >
                {t('MyProfile.DeleteAccountButton')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <DeleteDialogBox
            open={open}
            onClose={handleClose}
            onConfirm={handleDeleteAccount}
            itemName={''}
            newDialog={`${t('MyProfile.DeleteAccountMessage1')} ${username} ${t('MyProfile.DeleteAccountMessage2')}`}
        />
      </Container>
  );
}

export default MyProfile;