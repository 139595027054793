import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, getDoc } from 'firebase/firestore';
import { getFirestoreLocation } from '../../firebaseConfig';
import { fetchFromRedis, saveToRedis } from '../../utils/redisUtils';
import { useSnackbar } from '../SnackbarContext';
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next';

function ProcessPlotData({
  orgId,
  plotId,
  formId,
  selectedYear,
  selectedWeek,
  rows,
  columns,
  onPestDiseaseDataFetched,
  onRawPestDiseaseDataFetched,
  location,
  setIsProcessing,
}) {
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [isCachedDataUsed, setIsCachedDataUsed] = useState(false);
  const REDIS_KEY_PREFIX = 'plot-data';
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (!orgId || !plotId || !formId || !selectedYear || !selectedWeek) {
      Sentry.captureException(error);
      return;
    }

    const cacheKey = `${REDIS_KEY_PREFIX}_${orgId}_${plotId}_${formId}_${selectedYear}_${selectedWeek}`;
    const lastUpdatedKey = `${cacheKey}_lastUpdated`;

    let unsubscribeSubmissions = null;

    const checkAndUpdateData = async () => {
      setIsProcessing(true); // Start processing

      try {
        const cachedLastUpdated = await fetchFromRedis(lastUpdatedKey, location);
        const weekRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId, 'years', `${selectedYear}`, 'weeks', `${selectedWeek}`);
        const weekSnapshot = await getDoc(weekRef);
        const weekLastUpdated = weekSnapshot.data()?.lastUpdated?.toMillis();

        const cachedData = await fetchFromRedis(`${REDIS_KEY_PREFIX}_${cacheKey}_processed`, location);
        const cachedRawData = await fetchFromRedis(`${REDIS_KEY_PREFIX}_${cacheKey}_raw`, location);

        if (cachedLastUpdated === weekLastUpdated) {
          onPestDiseaseDataFetched(JSON.parse(cachedData));
          onRawPestDiseaseDataFetched(JSON.parse(cachedRawData));
          setIsProcessing(false); // End processing
          return;
        }

        const submissionsCollectionRef = collection(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId, 'years', `${selectedYear}`, `weeks`, `${selectedWeek}`, 'submissions');
        unsubscribeSubmissions = onSnapshot(submissionsCollectionRef, async (submissionSnapshot) => {
          let data = { pests: {}, diseases: {}, camera: Array.from({ length: rows }, () =>
                Array.from({ length: columns }, () => false)
            ) };
          let rawPestData = Array.from({ length: rows }, () => Array.from({ length: columns }, () => []));
          let rawDiseaseData = Array.from({ length: rows }, () => Array.from({ length: columns }, () => []));

          submissionSnapshot.forEach(doc => {
            processSubmissions(doc.data(), doc.id, data, rawPestData, rawDiseaseData);
          });

          const averagedData = JSON.stringify({
            pests: averageData(data.pests),
            diseases: averageData(data.diseases),
            camera: data.camera
          });

          const rawPestDiseaseData = JSON.stringify({ pests: rawPestData, diseases: rawDiseaseData });

          await saveToRedis(`${REDIS_KEY_PREFIX}_${cacheKey}_processed`, averagedData, location);
          await saveToRedis(`${REDIS_KEY_PREFIX}_${cacheKey}_raw`, rawPestDiseaseData, location);
          await saveToRedis(lastUpdatedKey, weekLastUpdated, location);

          onPestDiseaseDataFetched(JSON.parse(averagedData));
          onRawPestDiseaseDataFetched(JSON.parse(rawPestDiseaseData));
          setIsProcessing(false); // End processing
        });
      } catch (error) {
        setIsProcessing(false); // End processing
        showSnackbar(t('Plots.Snackbar.FetchDataError'), 'error');
        Sentry.captureException(error);
      }
    };

    if (!isCachedDataUsed) {
      checkAndUpdateData();
      setIsCachedDataUsed(true);
    }

    return () => {
      if (unsubscribeSubmissions) unsubscribeSubmissions();
    };
  }, [orgId, plotId, formId, selectedYear, selectedWeek, location, rows, columns, firestore, onPestDiseaseDataFetched, onRawPestDiseaseDataFetched, setIsProcessing]);

  useEffect(() => {
    setIsCachedDataUsed(false);
  }, [selectedYear, selectedWeek]);

  function isValidCellPosition(cellPosition) {
    if (!cellPosition || typeof cellPosition.row !== 'number' || typeof cellPosition.column !== 'number') {
      console.error('Invalid cellPosition structure:', cellPosition);
      return false;
    }
    if (cellPosition.row < 0 || cellPosition.row >= rows || cellPosition.column < 0 || cellPosition.column >= columns) {
      console.error('Invalid cellPosition:', cellPosition, 'Expected rows:', rows, 'columns:', columns);
      return false;
    }
    return true;
  }

  function processData(category, type, value, cellPosition, data) {
    if (!data[category][type]) {
      data[category][type] = Array.from({ length: rows }, () =>
        Array.from({ length: columns }, () => ({ sum: 0, count: 0 }))
      );
    }
    if (isValidCellPosition(cellPosition)) {
      const cell = data[category][type][cellPosition.row][cellPosition.column];
      cell.sum += value;
      cell.count += 1;
    }
  }

  function processCamera(cellPosition, value, data) {
    data['camera'][cellPosition.row][cellPosition.column] = data['camera'][cellPosition.row][cellPosition.column] || value;
  }

  function processRawData(type, level, submittedAt, hasImage, imageTracker, docId, cellPosition, rawData, comment = '') {
    if (isValidCellPosition(cellPosition)) {
      rawData[cellPosition.row][cellPosition.column].push(`Type:${type} Level:${level} Submitted At:${submittedAt} DocId:${docId} HasImage:${hasImage}, ImageTracker:${JSON.stringify(imageTracker)}, Comment:${comment}`);
    }
  }

  function averageData(data) {
    const averagedData = {};
    Object.keys(data).forEach((type) => {
      averagedData[type] = data[type].map((row) =>
        row.map(cell => cell.count > 0 ? cell.sum / cell.count : 0)
      );
    });
    return averagedData;
  }

  function processPestSubmission(submission, docId, data, rawPestData) {
    const { pestType, pestLevel, cellPosition, submittedAt, pestImage, imageTracker=[], comment } = submission;
    if (!isValidCellPosition(cellPosition)) {
      return;
    }
    const pestValue = pestLevel === 'Low' ? 1 : pestLevel === 'Medium' ? 2 : pestLevel === 'High' ? 3 : 0;

    processData('pests', pestType, pestValue, cellPosition, data);
    processRawData(pestType, pestLevel, submittedAt, pestImage, imageTracker, docId, cellPosition, rawPestData, comment);
    processCamera(cellPosition, pestImage, data);
  }

  function processDiseaseSubmission(submission, docId, data, rawDiseaseData) {
    const { diseaseType, diseaseLevel, cellPosition, submittedAt, diseaseImage, imageTracker=[], comment } = submission;
    if (!isValidCellPosition(cellPosition)) {
      return;
    }
    const diseaseValue = diseaseLevel === 'Low' ? 1 : diseaseLevel === 'Medium' ? 2 : diseaseLevel === 'High' ? 3 : 0;

    processData('diseases', diseaseType, diseaseValue, cellPosition, data);
    processRawData(diseaseType, diseaseLevel, submittedAt, diseaseImage, imageTracker, docId, cellPosition, rawDiseaseData, comment);
    processCamera(cellPosition, diseaseImage, data);
  }

  function processSubmissions(submission, docId, data, rawPestData, rawDiseaseData) {
    if (submission.pestType && submission.pestLevel) {
      processPestSubmission(submission, docId, data, rawPestData);
    }
    if (submission.diseaseType && submission.diseaseLevel) {
      processDiseaseSubmission(submission, docId, data, rawDiseaseData);
    }
  }

  return null;
}

export default ProcessPlotData;
