import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  updateDoc,
  deleteDoc,
  deleteField
} from 'firebase/firestore';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import useOrgFirestore from '../hooks/useOrgFirestore';
import { useSnackbar } from '../components/SnackbarContext';
import { sendNotification } from '../utils/SendNotification';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function OrganizationMembers() {
  const { orgId } = useParams();
  const [users, setUsers] = useState([]);
  const [newMemberUsername, setNewMemberUsername] = useState('');
  const [orgWrites, setOrgWrites] = useState({ currentWrites: 0, maximumWrites: 100 });
  const [orgTotalData, setOrgTotalData] = useState({ currentTotalData: 0, maximumTotalData: 100 });
  const [currentMemberCount, setCurrentMemberCount] = useState(0);
  const [maxMembers, setMaxMembers] = useState(100);
  const { f, location } = useOrgFirestore(orgId);

  const { firestore, storageBucket } = getFirestoreLocation(location);
  const { firestore: defFirestore, storageBucket: defStorageBucket } = getFirestoreLocation('default');
  const [curUserRole, setCurUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orgName, setOrgName] = useState('');
  const { showSnackbar } = useSnackbar();
  const curUser = auth.currentUser;
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDetails, setDialogDetails] = useState({ userId: '', currentRole: '', newRole: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // States for permission management toggle
  const [permissionManagement, setPermissionManagement] = useState(false);
  const [defaultUserPerms, setDefaultUserPerms] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const checkAuthAndLoadData = async () => {
      if (curUser && firestore && defFirestore) {
        await loadOrgWrites();
        await loadMembers(curUser);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    checkAuthAndLoadData();
  }, [firestore, defFirestore, curUser]);

  useEffect(() => {
    if (!loading && users.length === 0) {
      loadMembers(curUser);
      loadOrgWrites();
    }
  }, [loading, users, orgWrites, curUser]);

  const loadOrgWrites = async () => {
    try {
      const orgRef = doc(firestore, `organizations/${orgId}`);
      const orgSnapshot = await getDoc(orgRef);

      if (orgSnapshot.exists()) {
        const { currentWrites, maximumWrites, currentTotalData, maximumTotalData, name, permissionManagement, defaultUserPerms } = orgSnapshot.data();
        setOrgWrites({ currentWrites, maximumWrites });
        setOrgTotalData({ currentTotalData, maximumTotalData });
        setOrgName(name);
        setPermissionManagement(permissionManagement || false);
        setDefaultUserPerms(defaultUserPerms || false);
        setToggleState(defaultUserPerms || false);
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  const calculateProgress = (current, max) => (current / max) * 100;

  const loadMembers = async () => {
    try {
      await new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
            unsubscribe();
          } else {
            reject(new Error(t('MyOrganisations.Snackbar.LoadMemberError')));
          }
        });
      });

      const userRef = doc(defFirestore, `users/${auth.currentUser.uid}/organizations/${orgId}`);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        setCurUserRole(userSnapshot.data().role);
      } else {
        showSnackbar(t('MyOrganisations.Snackbar.LoadMemberError'), 'error');
      }

      const orgsRef = collection(firestore, `organizations/${orgId}/users`);
      const snapshot = await getDocs(orgsRef);
      const usersData = [];

      snapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() });
      });
      setUsers(usersData);
      setCurrentMemberCount(usersData.length);
    } catch (error) {
      showSnackbar(t('MyOrganisations.Snackbar.LoadMemberError'), 'error');
      Sentry.captureException(error);
    }
  };

  const handleToggleChange = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmToggleChange = async () => {
    try {
      const orgRef = doc(firestore, `organizations/${orgId}`);
      await updateDoc(orgRef, { defaultUserPerms: !toggleState });
      setToggleState(!toggleState);
      showSnackbar(t('MyOrganisations.Snackbar.DefaultPermissionChanged'), 'success');
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const inviteMember = async () => {
    if (currentMemberCount >= maxMembers) {
      showSnackbar(t('MyOrganisations.TooManyMembers'), 'error');
      return;
    }

    const usersRef = collection(defFirestore, 'users');
    const userQuery = query(usersRef, where('email', '==', newMemberUsername));

    try {
      const snapshot = await getDocs(userQuery);

      if (!snapshot.empty) {
        const userId = snapshot.docs[0].id;
        const userOrgsRef = doc(defFirestore, `users/${userId}/organizations/${orgId}`);
        const orgUsersRef = doc(firestore, `organizations/${orgId}/users/${userId}`);

        const role = 'Invited';

        await setDoc(userOrgsRef, { role });
        await setDoc(orgUsersRef, { role, email: newMemberUsername });

        // Default permission value based on toggle state
        const defaultPermissionValue = toggleState;

        // Update userPermissions in all plots
        const plotsRef = collection(firestore, `organizations/${orgId}/plots`);
        const plotsSnapshot = await getDocs(plotsRef);
        for (const plotDoc of plotsSnapshot.docs) {
          await updateDoc(plotDoc.ref, {
            [`userPermissions.${userId}`]: defaultPermissionValue,
          });
        }

        // Update userPermissions in all forms
        const formsRef = collection(firestore, `organizations/${orgId}/forms`);
        const formsSnapshot = await getDocs(formsRef);
        for (const formDoc of formsSnapshot.docs) {
          await updateDoc(formDoc.ref, {
            [`userPermissions.${userId}`]: defaultPermissionValue,
          });
        }

        showSnackbar(t('MyOrganisations.MemberInviteSuccess'), 'success');
        await sendNotification(
            userId,
            `Invite to ${orgName}`,
            `You have been sent an invite to join ${orgName}. Go to 'Manage Organizations' to accept this invite.`,
            defFirestore
        );

        loadMembers(auth.currentUser);
      } else {
        showSnackbar(t('MyOrganisations.Snackbar.UserNotFoundError'), 'error');
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  const handleRoleChangeConfirm = async () => {
    const { userId, currentRole, newRole } = dialogDetails;
    const usersRef = doc(defFirestore, `users/${userId}/organizations/${orgId}`);
    const orgsRef = doc(firestore, `organizations/${orgId}/users/${userId}`);

    try {
      await updateDoc(usersRef, { role: newRole });
      await updateDoc(orgsRef, { role: newRole });

      await loadMembers(auth.currentUser);
      showSnackbar(t('MyOrganisations.Snackbar.UserRoleUpdated'), 'success');
      await sendNotification(userId, `Role change in ${orgName}`, `Your role in ${orgName} has been updated to ${newRole}. View the organization in 'Manage Organizations' for more information on your new permissions.`, defFirestore);
    } catch (error) {
      showSnackbar(t('MyOrganisations.Snackbar.UserRoleUpdated'), 'error');
      Sentry.captureException(error);
    } finally {
      setDialogOpen(false);
    }
  };

  const promoteUser = (userId, userRole) => {
    let newRole;
    if (userRole === 'Viewer') {
      newRole = 'Contributor';
    } else if (userRole === 'Contributor') {
      newRole = 'Admin';
    }

    setDialogDetails({ userId, currentRole: userRole, newRole });
    setDialogOpen(true);
  };

  const demoteUser = (userId, userRole) => {
    let newRole;
    if (userRole === 'Admin') {
      newRole = 'Contributor';
    } else if (userRole === 'Contributor') {
      newRole = 'Viewer';
    }

    setDialogDetails({ userId, currentRole: userRole, newRole });
    setDialogOpen(true);
  };

  const sendOwnershipTransfer = async (userId, userRole) => {
    const usersRef = doc(defFirestore, `users/${userId}/organizations/${orgId}`);
    const orgsRef = doc(firestore, `organizations/${orgId}/users/${userId}`);

    if (curUserRole !== 'Owner') {
      showSnackbar(t('MyOrganisations.NoPermission'), 'error');
      return;
    }

    if (userRole === 'Admin' && curUserRole === 'Owner') {
      await updateDoc(usersRef, { transferRequest: new Date().toISOString() });
      await updateDoc(orgsRef, { transferRequest: new Date().toISOString() });
    }

    showSnackbar(t('MyOrganisations.Snackbar.TransferOwnershipRequestSent'), 'success');

    await sendNotification(userId, `Owner transfer request for ${orgName}`, ` You have been sent a request to be the new owner of ${orgName}. Go to 'Manage Organizations' to respond to this request.`, defFirestore);

    await loadMembers(auth.currentUser);
  };

  const rescindOwnershipTransfer = async (userId, userRole) => {
    const usersRef = doc(defFirestore, `users/${userId}/organizations/${orgId}`);
    const orgsRef = doc(firestore, `organizations/${orgId}/users/${userId}`);

    if (curUserRole !== 'Owner') {
      showSnackbar(t('MyOrganisations.NoPermission'), 'error');
      return;
    }

    if (curUserRole === 'Owner') {
      await updateDoc(usersRef, { transferRequest: false });
      await updateDoc(orgsRef, { transferRequest: false });
    }

    showSnackbar(t('MyOrganisations.Snackbar.TransferOwnershipRequestRemoved'), 'success');

    await loadMembers(auth.currentUser);
  };

  const removeUser = async (userId, userRole) => {
    const usersRef = doc(defFirestore, `users/${userId}/organizations/${orgId}`);
    const orgsRef = doc(firestore, `organizations/${orgId}/users/${userId}`);

    try {
      await deleteDoc(usersRef);
      await deleteDoc(orgsRef);

      // Remove user from userPermissions in all plots
      const plotsRef = collection(firestore, `organizations/${orgId}/plots`);
      const plotsSnapshot = await getDocs(plotsRef);
      console.log(2);
      for (const plotDoc of plotsSnapshot.docs) {
        console.log(3);
        await updateDoc(plotDoc.ref, {
          [`userPermissions.${userId}`]: deleteField(),
        });
      }
      console.log(4);
      // Remove user from userPermissions in all forms
      const formsRef = collection(firestore, `organizations/${orgId}/forms`);
      const formsSnapshot = await getDocs(formsRef);
      for (const formDoc of formsSnapshot.docs) {
        await updateDoc(formDoc.ref, {
          [`userPermissions.${userId}`]: deleteField(),
        });
      }

      showSnackbar(t('MyOrganisations.Snackbar.RemovedUser'), 'success');
      await sendNotification(
          userId,
          `Removed from ${orgName}`,
          `You have been removed from ${orgName}. `,
          defFirestore
      );

      await loadMembers(auth.currentUser);
    } catch (error) {
      console.log(error);
      showSnackbar(t('MyOrganisations.Snackbar.RemovedUserError'), 'error');
      Sentry.captureException(error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  const roleDescriptions = {
    Owner: [
      t('MyOrganisations.RoleDesc.Owner.0'),
      t('MyOrganisations.RoleDesc.Owner.1'),
      t('MyOrganisations.RoleDesc.Owner.2'),
      t('MyOrganisations.RoleDesc.Owner.3')
    ],
    Admin: [
      t('MyOrganisations.RoleDesc.Admin.0'),
      t('MyOrganisations.RoleDesc.Admin.1'),
      t('MyOrganisations.RoleDesc.Admin.2')
    ],
    Contributor: [
      t('MyOrganisations.RoleDesc.Contributor.0')
    ],
    Viewer: [
      t('MyOrganisations.RoleDesc.Viewer.0')
    ],
    Invited: [
      t('MyOrganisations.RoleDesc.Invited.0')
    ]
  };

  const getRoleChanges = (currentRole, newRole) => {
    const currentPermissions = roleDescriptions[currentRole] || [];
    const newPermissions = roleDescriptions[newRole] || [];

    const gained = newPermissions.filter(permission => !currentPermissions.includes(permission));
    const lost = currentPermissions.filter(permission => !newPermissions.includes(permission));

    return { gained, lost };
  };

  return (
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {t('MyOrganisations.Submissions')}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {t('MyOrganisations.MonthlySubmissions')} {orgWrites.currentWrites} / {orgWrites.maximumWrites}
                </Typography>
                <LinearProgress
                    sx={{ height: '20px' }}
                    variant="determinate"
                    value={calculateProgress(orgWrites.currentWrites, orgWrites.maximumWrites)}
                />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {t('MyOrganisations.TotalSubmissions')} {orgTotalData.currentTotalData} / {orgTotalData.maximumTotalData}
                </Typography>
                <LinearProgress
                    sx={{ height: '20px' }}
                    variant="determinate"
                    value={calculateProgress(orgTotalData.currentTotalData, orgTotalData.maximumTotalData)}
                />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {t('MyOrganisations.InviteMember')}
                </Typography>
                <TextField
                    id="newMemberUsername"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={newMemberUsername}
                    onChange={(e) => setNewMemberUsername(e.target.value)}
                />
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={inviteMember}>
                  {t('MyOrganisations.InviteMember')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Toggle for Default Member Permission */}
          <Grid item xs={12}>
            <Card variant='outlined'>
              {permissionManagement && (curUserRole === 'Admin' || curUserRole === 'Owner') && (
                  <FormControlLabel
                      control={
                        <Switch
                            checked={toggleState}
                            onChange={handleToggleChange}
                        />
                      }
                      label={t('MyOrganisations.DefaultMemberPermission')}
                  />
              )}
            </Card>
          </Grid>
          {users.map((user) => (
              <Grid item xs={12} key={user.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography variant="h6" component="h2">
                          {user.email}
                        </Typography>
                        <Typography color="textSecondary">
                          {t('MyOrganisations.Role')} {user.role}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" component="div">
                          <ul>
                            {roleDescriptions[user.role].map((desc, index) => (
                                <li key={index}>{desc}</li>
                            ))}
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {(user.role === 'Viewer' && (curUserRole === 'Owner' || curUserRole === 'Admin') || (user.role === 'Contributor' && curUserRole === 'Owner')) && (
                        <Button variant="contained" onClick={() => promoteUser(user.id, user.role)}>
                          {t('MyOrganisations.Promote')}
                        </Button>
                    )}
                    {(user.role === 'Admin' && (curUserRole === 'Owner') || user.role === 'Contributor' && (curUserRole === 'Admin' || curUserRole === 'Owner')) && (
                        <Button variant="contained" onClick={() => demoteUser(user.id, user.role)}>
                          {t('MyOrganisations.Demote')}
                        </Button>
                    )}
                    {(user.role !== 'Owner' && curUserRole === 'Owner' || curUserRole === 'Admin' && (user.role === 'Viewer' || user.role === 'Contributor' || user.role === 'Invited')) && (
                        <Button variant="contained" onClick={() => removeUser(user.id, user.role)}>
                          {t('MyOrganisations.Remove')}
                        </Button>
                    )}

                    {(user.role === 'Admin' && !user.transferRequest || user.transferRequest) && (
                        <>
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            {(user.role === 'Admin' && !user.transferRequest) && (
                                <MenuItem onClick={() => sendOwnershipTransfer(user.id, user.role)}>
                                  {t('MyOrganisations.TransferOwnership')}
                                </MenuItem>
                            )}
                            {user.transferRequest && (
                                <MenuItem onClick={() => rescindOwnershipTransfer(user.id, user.role)}>
                                  {t('MyOrganisations.RevokeTransferOwnership')}
                                </MenuItem>
                            )}
                          </Menu>
                        </>
                    )}
                  </CardActions>
                </Card>
              </Grid>
          ))}
        </Grid>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{t('MyOrganisations.ConfirmRoleChange')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('MyOrganisations.ConfirmRoleChangeFrom')} <strong>{dialogDetails.currentRole}</strong> {t('MyOrganisations.ConfirmRoleChangeTo')} <strong>{dialogDetails.newRole}</strong>.
            </DialogContentText>
            <DialogContentText>
              {t('MyOrganisations.ConfirmRoleChangePermissionGained')}
            </DialogContentText>
            <ul style={{ fontFamily: 'Arial, sans-serif' }}>
              {getRoleChanges(dialogDetails.currentRole, dialogDetails.newRole).gained.map((perm, index) => (
                  <li key={index}>{perm}</li>
              ))}
            </ul>
            <DialogContentText>
              {t('MyOrganisations.ConfirmRoleChangePermissionLost')}
            </DialogContentText>
            <ul style={{ fontFamily: 'Arial, sans-serif' }}>
              {getRoleChanges(dialogDetails.currentRole, dialogDetails.newRole).lost.map((perm, index) => (
                  <li key={index}>{perm}</li>
              ))}
            </ul>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleRoleChangeConfirm} sx={{color:'primary.main'}}>
              {t('MyOrganisations.Accept')}
            </Button>
            <Button onClick={() => setDialogOpen(false)}>{
              t('MyOrganisations.Decline')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
          <DialogTitle>{t('MyOrganisations.ConfirmToggleChangeTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <ul>
                <li>{t('MyOrganisations.ConfirmToggleChangeDescription1')}</li>
                <li>{t('MyOrganisations.ConfirmToggleChangeDescription2')}</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmToggleChange} sx={{color:'primary.main'}} >
              {t('MyOrganisations.Accept')}
            </Button>
            <Button onClick={() => setConfirmDialogOpen(false)}>
              {t('MyOrganisations.Decline')}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
}

export default OrganizationMembers;
